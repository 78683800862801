import React from 'react';
import { css } from '@emotion/react';
import Head from 'next/head';

// TODO: 非adminアプリにおけるuseHeaderInfo/HeaderInfoContext的なものを作成したら
//       titleを動的に出し分けるようにした方がよさそう
const AdminAppBar = React.memo(function AdminAppBar() {
  return (
    <div css={styles.appBar}>
      <Head>
        <title>{'Loglass Admin'}</title>
      </Head>

      <div css={styles.appBarLeft}>
        <div css={styles.logoArea}>
          <img alt="loglass" src="/assets/logomark_white.svg" width="auto" height="20" />
          <span css={styles.logoText}>Admin</span>
        </div>
      </div>
    </div>
  );
});

const styles = {
  appBar: css`
    padding: var(--spacing-4) var(--spacing-5);
    box-shadow: var(--shadow-floating-container);
    height: 56px;
    display: flex;
    background-color: var(--color-primary);
  `,

  appBarLeft: css`
    padding-top: var(--spacing-2);
    display: flex;
  `,

  logoArea: css`
    flex-shrink: 2;
    width: 184px;
  `,

  logoText: css`
    margin-left: var(--spacing-2);
    line-height: 24px;
    font-weight: bold;
    color: var(--color-neutral-1);
    font-size: var(--font-size--l);
  `,
};

export { AdminAppBar };
