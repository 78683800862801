import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

type PopoverPanelProps = {
  children?: ReactNode;
  maxHeightPx?: number;
};

const PopoverPanel = React.memo(function PopoverPanel({
  children,
  maxHeightPx,
}: PopoverPanelProps): JSX.Element {
  return (
    <div
      css={[
        styles.panel,
        maxHeightPx &&
          css`
            max-height: ${maxHeightPx}px;
          `,
      ]}
    >
      {children}
    </div>
  );
});

const styles = {
  panel: css`
    box-shadow: var(--shadow-floating-container);
    background-color: var(--color-base-background);
    border-radius: 4px;
    max-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-5);
  `,
};

export { PopoverPanel };
