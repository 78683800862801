import React, { ReactElement, ReactNode, useCallback, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import { ModalContext, ModalContextProps } from './ModalContext';

const OPEN_DURATION_MS = 150;
const CLOSE_DURATION_MS = 150;

type ModalProviderProps = {
  children?: ReactNode;
};

type ModalOptions = {
  closeIfClickBackground: boolean;
};

function ModalProvider({ children }: ModalProviderProps): JSX.Element {
  const router = useRouter();
  const [modal, setModal] = useState<ReactElement | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [openModalTimerId, setOpenModalTimerId] = useState<NodeJS.Timeout | null>(null);
  const [closeIfClickBackground, setCloseIfClickBackground] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const openModal = useCallback((newModal: ReactElement, options: ModalOptions | undefined) => {
    setModal(newModal);
    setCloseIfClickBackground(Boolean(options?.closeIfClickBackground));
    const id = setTimeout(() => {
      setOpen(true);
      setOpenModalTimerId(null);
      if (modalRef.current) {
        modalRef.current.focus();
      }
    }, OPEN_DURATION_MS);
    setOpenModalTimerId(id);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setModal(undefined);
    }, CLOSE_DURATION_MS);
  }, []);

  // ページ遷移イベント完了時、パスが異なる場合はにモーダルを閉じる
  const handleRouteChangeComplete = useCallback(
    (changedUrl: string) => {
      if (changedUrl !== router.pathname && open) closeModal();
    },
    [closeModal, open, router.pathname],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      // setTimeoutが発火される前にコンポーネントが破棄された場合、setTimeout自体がメモリに残ってしまうのでコンポーネント破棄時にsetTimeoutをクリアする
      if (openModalTimerId) {
        clearInterval(openModalTimerId);
      }
    };
  });

  const closeModalWhenEscapeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };

  return (
    <div>
      <MemorizedModalProvider openModal={openModal} closeModal={closeModal}>
        {children}
      </MemorizedModalProvider>
      {modal && (
        <div
          css={[
            styles.modalBackground,
            open ? styles.openModalBackground : styles.closeModalBackground,
          ]}
          onClick={() => {
            if (closeIfClickBackground) {
              closeModal();
            }
          }}
          ref={modalRef}
          onKeyDown={closeModalWhenEscapeKeyDown}
          tabIndex={-1}
        >
          <div
            css={styles.modalContainer}
            onClick={(e) => {
              if (closeIfClickBackground) e.stopPropagation();
            }}
          >
            <div css={open ? styles.openedModal : styles.closedModal}>{modal}</div>
          </div>
        </div>
      )}
    </div>
  );
}

const MemorizedModalProvider: React.FC<ModalContextProps> = React.memo(
  function MemorizedModalProvider({ openModal, closeModal, children }) {
    return (
      <ModalContext.Provider value={{ openModal, closeModal }}>{children}</ModalContext.Provider>
    );
  },
);

const styles = {
  modalBackground: css`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-transparent-dark);
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  openModalBackground: css`
    opacity: 1;
    transition: opacity ${OPEN_DURATION_MS}ms linear;
  `,

  closeModalBackground: css`
    opacity: 0;
    transition: opacity ${CLOSE_DURATION_MS}ms linear;
  `,

  modalContainer: css`
    display: flex;
  `,

  openedModal: css`
    opacity: 1;
    transform: none;
    transition: all ${OPEN_DURATION_MS}ms cubic-bezier(0, 0, 0.2, 1);
  `,

  closedModal: css`
    opacity: 0;
    transform: scale(0.5);
    transition: all ${CLOSE_DURATION_MS}ms cubic-bezier(0.4, 0, 0.2, 1);
  `,
};

export { ModalProvider };
export type { ModalOptions };
