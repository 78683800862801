import { createContext, useContext } from 'react';

type DrawerContextValue = {
  onClose: () => void;
};

const DrawerContext = createContext<DrawerContextValue | null>(null);

const useDrawer = () => {
  const value = useContext(DrawerContext);
  if (value == null) {
    throw new Error('DrawerContext でラップするのを忘れています。');
  }
  return value;
};

const DrawerProvider = DrawerContext.Provider;

export { useDrawer, DrawerProvider };
