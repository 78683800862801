import { Auth0Role, Role } from '~/common/providers/auth/role';

export type TenantId = string;
export type UserId = string;
export type RoleId = string;

export type LoginUserInfo = {
  tenantId: TenantId;
  firstName: string;
  lastName: string;
  userId: UserId;
  role: Role;
  roleId: RoleId;
};

export type LoginTenantInfo = {
  tenantId: TenantId;
  tenantName: string;
};

export type LoginUser = {
  roles: Auth0Role[];
  userInfo: LoginUserInfo;
  tenantInfo: LoginTenantInfo;
  nickname?: string;
  name?: string;
  picture?: string;
  updated_at?: string;
  email?: string;
  email_verified?: boolean;
  sub?: string;
};

export const fullName = (user: { lastName: string; firstName: string }): string =>
  `${user.lastName} ${user.firstName}`;
