import React, { ReactNode, StrictMode } from 'react';
import { ToastProvider } from '~/common/components/toast';
import { ErrorBoundary } from '~/app/ErrorBoundary';
import { ModalProvider } from '~/common/components/modal';
import { HookPopoverProvider } from '~/common/components/popover/HookPopoverProvider';
import { AdminAuthProvider } from '~/common/providers/admin-auth/AdminAuthProvider';
import { AdminAuthGuard } from '~/common/providers/admin-auth/AdminAuthGuard';
import { AdminLayoutContainer } from '~/app/AdminLayoutContainer';

type Props = {
  children: ReactNode;
};

const AdminAppProvider: React.FC<Props> = ({ children }) => (
  <StrictMode>
    <ErrorBoundary>
      <AdminAuthProvider>
        <AdminAuthGuard>
          <ToastProvider>
            <ModalProvider>
              <HookPopoverProvider>
                <AdminLayoutContainer>{children}</AdminLayoutContainer>
              </HookPopoverProvider>
            </ModalProvider>
          </ToastProvider>
        </AdminAuthGuard>
      </AdminAuthProvider>
    </ErrorBoundary>
  </StrictMode>
);

export { AdminAppProvider };
