import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { useReloadAllUnauthorizedTabs } from '~/common/providers/auth/use-reload-all-unauthorized-tabs';
import { LoginFailedReason } from '~/features/login/LoginFailedContainer';

type Props = {
  children?: ReactNode;
};

const AuthGuard: React.FC<Props> = React.memo(function AuthGuard({ children }) {
  const { push, asPath, reload, pathname } = useRouter();
  const { loginWithRedirect, isAuthenticated, isLoading, logout, error } = useAuth0();

  useReloadAllUnauthorizedTabs(logout);

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (error) {
    // 発生したエラーは無視するものも含めとりあえず info で出力する
    datadogLogs.logger.info(error.message ?? '', {
      error: { name: error?.name, message: error?.message, stack: error?.stack },
    });

    if (error.message === 'Invalid state') {
      // ログイン画面からのリダイレクトで state が不正な場合は Callback.tsx のリダイレクトで対応する
      if (pathname.endsWith('/login/callback')) {
        return <>{children}</>;
      }
      if (asPath.endsWith('/')) {
        reload(); // ルートに遷移した上で Invalid state 継続の場合はリロードする
      }
      push('/'); // エラー時にはルートに遷移させる（url param を消すため）
      return null;
    }

    // 規定されたエラーの場合は error で出力する
    if (!OK_ERROR_REASONS.includes(error.message as LoginFailedReason)) {
      datadogLogs.logger.error(error.message ?? '', {
        error: { name: error?.name, message: error?.message, stack: error?.stack },
      });
    }

    const redirectPath = error.message ? `?reason=${encodeURI(error.message)}` : '';

    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login/failed${redirectPath}`,
      },
    });

    return null;
  }

  // 未認証時、認証画面にリダイレクトし、認証後にアクセスしようとしていたページにリダイレクトする
  loginWithRedirect({ appState: { returnTo: asPath ?? '/' } });

  return null; // Valid な ReactNode を返す
});

const OK_ERROR_REASONS: LoginFailedReason[] = [
  'exist_loglass_create_user_not_found',
  'User did not authorize the request',
  'required_data_not_found',
  'Access to Loglass is not allowed. IP Address access restrictions.',
];

export { AuthGuard };
