import React, { ReactNode } from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { env } from '~/app/environment';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { useTenant } from '~/common/hooks/use-tenant';

type GoogleTagManagerProviderProps = {
  children?: ReactNode;
};

const GoogleTagManagerProvider = React.memo(function GoogleTagManagerProvider({
  children,
}: GoogleTagManagerProviderProps): JSX.Element {
  const loginUser = useLoginUser();
  const { data: tenant } = useTenant();
  const environment = env();
  const gtmParams = () => {
    const environmentParams =
      environment?.googleTagManager.gtm_auth && environment?.googleTagManager.gtm_preview
        ? {
            gtm_auth: environment?.googleTagManager.gtm_auth,
            gtm_preview: environment?.googleTagManager.gtm_preview,
          }
        : undefined;

    return {
      id: environment?.googleTagManager.gtm_id ?? '',
      dataLayer: {
        userId: loginUser?.userInfo?.userId ?? '',
        // FIXME: この時点で useTenant を使用したテナント情報の取得が完了していない可能性があるので auth0 経由の tenantInfo を使う。
        // 本当はここに到達する時点で必ず tenantId, tenantName が参照できる状態にしたい
        tenantId: loginUser?.tenantInfo?.tenantId ?? '',
        tenantName: tenant?.name ?? '',
        emailAddress: loginUser?.email ?? '',
        firstName: loginUser?.userInfo?.firstName ?? '',
        lastName: loginUser?.userInfo?.lastName ?? '',
        role: loginUser?.userInfo?.role ?? '',
      },
      environment: environmentParams,
    };
  };

  return <GTMProvider state={gtmParams()}>{children}</GTMProvider>;
});

export { GoogleTagManagerProvider };
