import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Button } from '~/common/components/button';

type ModalFooterProps = {
  okButtonLabel: string;
  cancelButtonLabel?: string;
  isDangerOkButton?: boolean;
  isOkButtonDisabled?: boolean;
  onOk: () => Promise<void> | void;
  onCancel?: () => void;
  children?: ReactNode;
};

const ModalFooter: React.FC<ModalFooterProps> = React.memo(function ModalFooter({
  okButtonLabel,
  cancelButtonLabel = 'キャンセル',
  isDangerOkButton = false,
  isOkButtonDisabled = false,
  onOk,
  onCancel,
  children,
}) {
  return (
    <footer css={styles.modalFooter}>
      {children && <div>{children}</div>}
      <div css={styles.left}>
        {onCancel && (
          <Button variant="secondary" appearance="fill" onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
        )}
        <div css={styles.okButton}>
          <Button
            variant={isDangerOkButton ? 'danger' : 'primary'}
            appearance="fill"
            disabled={isOkButtonDisabled}
            onClick={onOk}
          >
            {okButtonLabel}
          </Button>
        </div>
      </div>
    </footer>
  );
});

const styles = {
  modalFooter: css`
    display: flex;
    align-items: center;
    padding: var(--spacing-5);
    width: 100%;
    background-color: var(--color-page-background);
    border-top: 1px solid var(--color-element-border);
    box-sizing: border-box;
  `,

  left: css`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  `,

  okButton: css`
    margin-left: var(--spacing-5);
  `,
};

export { ModalFooter };
export type { ModalFooterProps };
