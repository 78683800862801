import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import Link from 'next/link';
import { css } from '@emotion/react';

type Props = {
  id?: string;
  href: string;
  children: ReactNode;
  target?: HTMLAttributeAnchorTarget;
  dataTestId?: string;
};

const InternalLink: React.FC<Props> = React.memo(function InternalLink({
  id,
  href,
  children,
  dataTestId,
  target,
}) {
  return (
    <Link href={href} id={id} css={styles.link} data-testid={dataTestId} target={target}>
      {children}
    </Link>
  );
});

const styles = {
  link: css`
    & > * {
      width: 100%;
    }
  `,
};

export { InternalLink };
