import { datadogLogs } from '@datadog/browser-logs';
import { LoginUser } from '~/common/providers/auth/login-user';

const setDatadogLogsUserInfo = (loginUser?: LoginUser): void => {
  if (loginUser && loginUser.userInfo) {
    datadogLogs.logger.setContextProperty('userId', loginUser.userInfo.userId);
    datadogLogs.logger.setContextProperty('tenantId', loginUser.userInfo.tenantId);
  } else {
    // ログインに失敗している場合は、UNKNOWNで埋める
    datadogLogs.logger.setContextProperty('userId', 'UNKNOWN');
    datadogLogs.logger.setContextProperty('tenantId', 'UNKNOWN');
  }
};

export { setDatadogLogsUserInfo };
