import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { isInternetExplorer } from '~/common/utils/detect-browser';
import { useReloadBeforeRoutingWhenNewAppIsReleased } from '~/app/use-reload-before-routing-when-new-app-is-released';
import { env, getEnvironmentName } from '~/app/environment';
import { AdminAppProvider } from '~/app/AdminAppProvider';
import { CustomAppProvider } from '~/app/CustomAppProvider';
import { LabsAppProvider } from '~/app/LabsAppProvider';

type PageProps = {
  isAdminPage: boolean;
  isLabsPage: boolean;
  noAuth: boolean;
};

const App: React.FC<AppProps<PageProps>> = ({ Component, pageProps }) => {
  useEffect(() => {
    if (isInternetExplorer(window.navigator.userAgent)) {
      alert('使用中のブラウザはサポート外です。Google Chromeをお使いください。');
    }
  }, []);

  // 新しいバージョンがリリースされていたら、ルーティング時にリロード処理を挟む
  useReloadBeforeRoutingWhenNewAppIsReleased();

  const environment = env();
  const environmentName = getEnvironmentName(environment);
  if (environmentName) {
    datadogRum.init({
      applicationId: env()?.datadog.rum.applicationId ?? '',
      clientToken: env()?.datadog.rum.clientToken ?? '',
      site: env()?.datadog.site,
      service: 'loglass-web',
      env: environmentName,
      version: process.env.NEXT_PUBLIC_COMMIT_HASH,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      defaultPrivacyLevel: 'mask',
      silentMultipleInit: true,
      trackUserInteractions: true,
      enableExperimentalFeatures: ['clickmap'],
    });

    datadogRum.startSessionReplayRecording();
  }
  // Local環境では要素クラス名デバッグがしやすいように、Emotionランタイムの自動ラベル付与機能を有効化する
  if (environmentName === undefined && environment !== undefined) {
    globalThis.EMOTION_RUNTIME_AUTO_LABEL = true;
  }

  // adminページの場合
  if (pageProps.isAdminPage) {
    return (
      <AdminAppProvider>
        <Component {...pageProps} />
      </AdminAppProvider>
    );
  }

  // labページの場合
  if (pageProps.isLabsPage) {
    return (
      <LabsAppProvider noAuth={pageProps.noAuth}>
        <Component {...pageProps} />
      </LabsAppProvider>
    );
  }

  // 通常のappページ
  return (
    <CustomAppProvider noAuth={pageProps.noAuth}>
      <Component {...pageProps} />
    </CustomAppProvider>
  );
};

type CustomAppProps = {
  props: {
    noAuth: boolean;
  };
};

type AdminAppProps = {
  props: {
    isAdminPage: boolean;
  };
};

type LabsAppProps = {
  props: {
    isLabsPage: boolean;
  };
};

export { App };
export type { CustomAppProps, AdminAppProps, LabsAppProps };
