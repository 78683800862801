import React, { createContext, ReactElement, ReactNode, useCallback, useState } from 'react';
import { css } from '@emotion/react';

type HookPopoverContextProps = {
  children?: ReactNode;
  openPopover: (popover: ReactElement, targetElement: HTMLElement) => void;
  closePopover: () => void;
};

const HookPopoverContext = createContext<HookPopoverContextProps>({
  openPopover: () => undefined,
  closePopover: () => undefined,
  children: undefined,
});

type HookPopoverProviderProps = {
  children?: ReactNode;
};

function HookPopoverProvider({ children }: HookPopoverProviderProps): JSX.Element {
  const [popover, setPopover] = useState<ReactElement | undefined>(undefined);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const openPopover = useCallback((newPopover: ReactElement, targetElement: HTMLElement) => {
    const { top, left } = targetElement.getBoundingClientRect();
    setTop(top);
    setLeft(left);
    setPopover(newPopover);
  }, []);

  const closePopover = useCallback(() => {
    setPopover(undefined);
    setTop(0);
    setLeft(0);
  }, []);

  return (
    <div
      css={styles.container}
      onClick={() => {
        if (popover) setPopover(undefined);
      }}
    >
      <MemorizedHookPopoverProvider openPopover={openPopover} closePopover={closePopover}>
        {children}
      </MemorizedHookPopoverProvider>
      {popover && (
        <div css={[styles.position, positionStyle(top, left)]} onClick={(e) => e.stopPropagation()}>
          {popover}
        </div>
      )}
    </div>
  );
}

const MemorizedHookPopoverProvider: React.FC<HookPopoverContextProps> = React.memo(
  function MemorizedHookPopoverProvider({ openPopover, closePopover, children }) {
    return (
      <HookPopoverContext.Provider value={{ openPopover, closePopover }}>
        {children}
      </HookPopoverContext.Provider>
    );
  },
);

const positionStyle = (top: number, left: number) => {
  return css`
    top: ${top}px;
    left: ${left}px;
  `;
};

const styles = {
  container: css`
    position: relative;
  `,
  position: css`
    z-index: 1100;
    position: absolute;
  `,
};

export { HookPopoverProvider, HookPopoverContext };
export type { HookPopoverContextProps };
