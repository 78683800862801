import React, { ReactNode, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { isClientSide } from '~/common/utils/is-client-side';

type Props = {
  children?: ReactNode;
};

const AdminAuthGuard: React.FC<Props> = React.memo(function AdminAuthGuard({ children }) {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated && isClientSide()) loginWithRedirect();
  });

  const isOk = () => !isLoading && isAuthenticated;

  return <>{isOk() && <>{children}</>}</>;
});

export { AdminAuthGuard };
