import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { AdminLayout } from '~/common/components/admin-layout';

type Props = {
  children?: ReactNode;
};

const AdminLayoutContainer: React.FC<Props> = React.memo(function AdminLayoutContainer({
  children,
}) {
  const router = useRouter();
  const currentUrl = router.pathname;

  return <AdminLayout currentUrl={currentUrl}>{children}</AdminLayout>;
});

export { AdminLayoutContainer };
