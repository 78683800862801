import { useContext } from 'react';
import { ModalContext, ModalContextProps } from '~/common/components/modal/ModalContext';

type UseModal = ModalContextProps;

const useModal = (): UseModal => {
  const { closeModal, openModal } = useContext(ModalContext);

  return {
    closeModal,
    openModal,
  };
};

export { useModal };
