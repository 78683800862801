import { createContext, ReactElement, ReactNode } from 'react';
import { ModalOptions } from '~/common/components/modal/ModalProvider';

export type ModalContextProps = {
  openModal: (modal: ReactElement, options?: ModalOptions) => void;
  closeModal: () => void;
  children?: ReactNode;
};

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => undefined,
  closeModal: () => undefined,
});
