import { SWRResponse } from 'swr';
import { useFetch, UseFetchOption } from '~/common/hooks/use-fetch';
import { TenantsControllerApi, TenantView } from '~/common/api';

const useTenant = (option?: UseFetchOption<TenantView>): SWRResponse<TenantView, Error> => {
  return useFetch(
    '/api/tenant',
    (reqOps) => new TenantsControllerApi().getTenant(reqOps).then((response) => response.data),
    option,
  );
};

export { useTenant };
