import { AlertCircle, AlertTriangle, CheckCircle, ChevronRight, Info, X } from 'react-feather';
import { css } from '@emotion/react';
import React from 'react';
import { Icon } from '~/common/components/icon';
import { Span } from '~/common/components/span';
import { assertNever } from '~/common/utils/assertNever';
import { IconColor } from '~/common/components/icon/Icon';

type ToastType = 'success' | 'warning' | 'error' | 'info';

type ToastProps = {
  type: ToastType;
  text: string;
  onClickButton?: () => void;
  dataTestId?: string;
};

/**
 * アクションに対するリアクションを表示する。
 * 一時的な情報の表示に利用する。
 **/
const Toast: React.FC<ToastProps> = React.memo(function Toast({
  type,
  text,
  onClickButton,
  dataTestId,
}) {
  const { StatusIcon, ActionIcon, color, IconColor } = switchTypeProps(type);

  const showButton = type !== 'success';

  return (
    <div data-testid={dataTestId} css={styles.container}>
      <div
        css={[
          styles.shadow,
          css`
            border-left-color: var(${color});
          `,
        ]}
      >
        <div css={[styles.status]}>
          <Icon size="m" color={IconColor}>
            <StatusIcon />
          </Icon>
        </div>
        <Span level={2} css={styles.text} prewrap>
          {text}
        </Span>
        {showButton && onClickButton && (
          <div css={styles.buttonContainer}>
            <Icon size="m" color="success" button={true} onClick={onClickButton}>
              <ActionIcon />
            </Icon>
          </div>
        )}
      </div>
    </div>
  );
});

const switchTypeProps = (
  type: ToastType,
): {
  StatusIcon: () => JSX.Element;
  ActionIcon: () => JSX.Element;
  color: '--color-success' | '--color-warning' | '--color-danger' | '--color-info';
  IconColor: IconColor;
} => {
  switch (type) {
    case 'success':
      return {
        StatusIcon: () => <CheckCircle />,
        ActionIcon: () => <></>,
        color: '--color-success',
        IconColor: 'success',
      } as const;
    case 'warning':
      return {
        StatusIcon: () => <AlertCircle />,
        ActionIcon: () => <X />,
        color: '--color-warning',
        IconColor: 'warn',
      } as const;
    case 'error':
      return {
        StatusIcon: () => <AlertTriangle />,
        ActionIcon: () => <X />,
        color: '--color-danger',
        IconColor: 'danger',
      } as const;
    case 'info':
      return {
        StatusIcon: () => <Info />,
        ActionIcon: () => <ChevronRight />,
        color: '--color-info',
        IconColor: 'info',
      } as const;
    default:
      return assertNever(type);
  }
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  shadow: css`
    width: 576px;
    max-height: 272px;
    overflow: hidden;
    position: relative;
    display: flex;
    background-color: var(--color-base-background);
    border-radius: var(--border-radius-m);
    border-left-style: solid;
    border-left-width: 6px; /* --border-width-xx 未実装のため直指定 */
    box-sizing: border-box;
    pointer-events: auto;
    box-shadow: var(--shadow-element-floating);
  `,
  status: css`
    padding: var(--spacing-5);
    font-size: 0;
    display: flex;
    align-items: flex-start;
  `,
  text: css`
    flex: 1;
    margin: var(--spacing-5) 0;
    padding: 0 var(--spacing-3) 0 0;
    word-break: break-all;
    overflow-y: auto;
  `,
  buttonContainer: css`
    background-color: var(--color-neutral-1);
    display: flex;
    align-items: flex-start;
    button {
      padding: var(--spacing-5) var(--spacing-5) var(--spacing-5) var(--spacing-3);
      /* TODO: ICON コンポーネントで色指定できるようにする。ZAN-431 で対応 */
      color: var(--color-neutral-9);
    }
  `,
};

export { Toast };
export type { ToastProps };
