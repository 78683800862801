import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { css } from '@emotion/react';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { Icon } from '~/common/components/icon';

type Props = {
  children: ReactNode;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  showIcon?: boolean; // TODO: これは過渡期対応。Help.tsxが撤去でき次第showIconプロパティは撤廃する
};

const ExternalLink: React.FC<Props> = React.memo(function ExternalLink({
  children,
  href,
  target,
  showIcon = true,
}) {
  return (
    <a href={href} css={styles.link} target={target} rel="noopener noreferrer">
      {children}
      {/* アイコンはtarget="_blank"が設定されていないと表示されない */}
      {showIcon && target === '_blank' && (
        <Icon size={'s'} color={'dark'}>
          <ExternalLinkIcon />
        </Icon>
      )}
    </a>
  );
});

const styles = {
  link: css`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-1);

    & > * {
      width: 100%;
    }
  `,
};

export { ExternalLink };
