import React, { ForwardedRef, ReactElement, useCallback } from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { buttonVariantStyle } from '~/common/components/button/button-color-style';
import { assertNever } from '~/common/utils/assertNever';
import { Icon } from '~/common/components/icon';
import { IconColor } from '~/common/components/icon/Icon';

type InlineButtonVariant = 'primary' | 'secondary' | 'danger';
type Size = 'xs' | 's';

type InlineButtonProps = {
  id?: string;
  variant: InlineButtonVariant;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: string | ReactElement;
  icon?: ReactElement;
  iconPosition?: 'left' | 'right';
  size?: Size;
  ariaLabel?: string;
};

const InlineButton = React.forwardRef(function Button(
  {
    id,
    children,
    variant = 'primary',
    disabled = false,
    onClick,
    icon,
    iconPosition = 'left',
    size = 'xs',
    ariaLabel,
  }: InlineButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const IconElem = useCallback(() => {
    if (!icon) {
      return <></>;
    }
    return (
      <Icon color={iconColor(variant)} size={'s'}>
        {icon}
      </Icon>
    );
  }, [icon, variant]);
  return (
    <button
      id={id}
      ref={ref}
      css={[
        styles.button,
        styles.inline,
        buttonVariantStyle(variant, 'ghost'),
        buttonIconStyle(icon),
        buttonSizeStyle(size),
      ]}
      disabled={disabled}
      onClick={onClick}
      type="button"
      aria-label={ariaLabel}
    >
      {iconPosition === 'left' ? <IconElem /> : <></>}
      {children}
      {iconPosition === 'right' ? <IconElem /> : <></>}
    </button>
  );
});

const iconColor = (variant: InlineButtonVariant): IconColor => {
  switch (variant) {
    case 'primary':
      return 'primary';
    case 'danger':
      return 'danger';
    case 'secondary':
      return 'dark';
    default:
      return assertNever(variant);
  }
};

const buttonSizeStyle = (size: Size) => (size === 'xs' ? styles.sizeXs : styles.sizeS);

const buttonIconStyle = (icon?: ReactElement): SerializedStyles | undefined => {
  // アイコン ＋ テキスト
  return icon ? styles.buttonWithIcon : undefined;
};

const styles = {
  button: css`
    border-radius: var(--border-radius-m);
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-family: var(--font-family-jp);
    font-weight: var(--font-weight-semi-bold);
    line-height: 16px;
    cursor: pointer;
    transition:
      background-color 300ms,
      box-shadow 300ms;
    box-shadow: transparent;

    &:disabled {
      opacity: 33%;
      cursor: not-allowed;
    }
  `,
  inline: css`
    line-height: 18px;
    padding: 0 var(--spacing-1);
  `,
  buttonWithIcon: css`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-2);
  `,
  sizeXs: css`
    font-size: var(--font-size--xs);
  `,
  sizeS: css`
    font-size: var(--font-size--s);
  `,
};

export { InlineButton };
export type { InlineButtonProps };
