import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

const MainTemplate: React.FC<{ children: ReactNode }> = React.memo(function MainTemplate({
  children,
}) {
  return <div css={[styles.wrapper]}>{children}</div>;
});

const styles = {
  wrapper: css`
    height: 100%; /* NOTE: 新UIの組織管理で100vhで表示させるための対応 */
    padding: var(--spacing-5) var(--spacing-7);
  `,
};

export { MainTemplate };
