import React, { PropsWithChildren, useEffect } from 'react';
import { css } from '@emotion/react';
import { DrawerPortal } from '~/common/components/drawer/DrawerPortal';
import { DrawerProvider } from '~/common/components/drawer/DrawerContext';

type DrawerProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}>;

const Drawer = React.memo(function Drawer({
  children,
  isOpen: isOpenForTransition,
  onClose,
  size,
}: DrawerProps) {
  const [isOpen, setIsOpen] = React.useState(isOpenForTransition);
  const handleWidthTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (e) => {
    if (e.target !== e.currentTarget || e.propertyName !== 'width' || isOpenForTransition) return;
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpenForTransition) {
      setIsOpen(true);
    }
  }, [isOpenForTransition]);

  return (
    <DrawerProvider value={{ onClose }}>
      <DrawerPortal>
        <div
          onTransitionEnd={handleWidthTransitionEnd}
          css={[
            getOpenDrawerWidth(size),
            styles.drawerArea,
            isOpenForTransition ? styles.openDrawerArea : styles.closeDrawerArea,
          ]}
        >
          {isOpen && <div css={styles.drawer}>{children}</div>}
        </div>
      </DrawerPortal>
    </DrawerProvider>
  );
});

function getOpenDrawerWidth(size?: DrawerProps['size']) {
  const widthMap = {
    small: css`
      --open-drawer-width: 240px;
    `,
    medium: css`
      --open-drawer-width: 288px;
    `,
    large: css`
      --open-drawer-width: 480px;
    `,
    xlarge: css`
      --open-drawer-width: 720px;
    `,
  };
  return widthMap[size ?? 'small'];
}

const styles = {
  drawerArea: css`
    grid-area: 1/1;
  `,
  closeDrawerArea: css`
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    transition:
      width 0.5s,
      height 0s 0.5s;
  `,
  openDrawerArea: css`
    width: var(--open-drawer-width);
    height: 100%;
    transition: width 0.5s;
  `,
  drawer: css`
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: var(--open-drawer-width);
    height: 100%;
    filter: drop-shadow(var(--shadow-floating-container));
    background-color: var(--color-base-background);
  `,
};

export { Drawer };
