import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { env } from '~/app/environment';

type Props = {
  children?: ReactNode;
};

const AdminAuthProvider: React.FC<Props> = React.memo(function AuthWrapper({ children }) {
  const environment = env();
  return (
    <>
      {environment && (
        <Auth0Provider
          domain={environment.adminAuth0.domain}
          clientId={environment.adminAuth0.client_id}
          authorizationParams={{
            redirect_uri: environment.adminAuth0.redirect_uri,
            audience: environment.adminAuth0.audience,
            organization: environment.adminAuth0.organization,
          }}
        >
          {children}
        </Auth0Provider>
      )}
    </>
  );
});

export { AdminAuthProvider };
