import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

type ScrollContainerProps = {
  children?: ReactNode;
  className?: string;
};

/**
 * コンテナ内部の一部でスクロールをしたい場合に親コンポーネントの高さを固定させる必要がある。
 * flex-direction: column + overflow: hiddenで親要素内の自分に割り当てられた高さに子要素を固定させることができる
 *
 * 使用例:
 * <ScrollContainer>
 *   <div><Heading level=4>ページタイトル</Heading><div>
 *   <Table></Table> // テーブルがスクロールできるようになる
 * </ScrollContainer>
 */
const ScrollContainer = React.memo(function ScrollContainer({
  children,
  className,
}: ScrollContainerProps) {
  return (
    <div css={styles.container} className={className}>
      {children}
    </div>
  );
});

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
};

export { ScrollContainer };
