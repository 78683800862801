// https://typescript-jp.gitbook.io/deep-dive/type-system/discriminated-unions#chekkunodewogeru
/**
 * TypeScriptの型判定状は通り得ないSwitch文のケースに入ってしまったときにエラー投げる関数
 *
 * 例）
 * type Kind = 'A' | 'B'
 * const kind: Kind = 'A'
 * switch (kind) {
 *   case 'A': return 1;
 *   case 'B': return 2;
 *   default: return assertNever(kind);
 * }
 */
const assertNever = (x: never): never => {
  throw new Error(`Unexpected value. Should have been never. ${JSON.stringify(x)}`);
};

export { assertNever };
