import { useState } from 'react';
import { getItemFromLocalStorage, setItemToLocalStorage } from '~/common/utils/local-storage';

type ToggleNavigationState = Record<string, boolean>;

const useToggleNavigationItem = () => {
  const [state, setState] = useState<ToggleNavigationState>(
    getItemFromLocalStorage<ToggleNavigationState>(TOGGLE_NAVIGATION_STORAGE_KEY) ?? {},
  );

  const setToggleNavigationState = (id: string) => {
    const localState = getItemFromLocalStorage<ToggleNavigationState>(
      TOGGLE_NAVIGATION_STORAGE_KEY,
    );
    const newState = { ...localState };
    newState[id] = newState[id] == null ? false : !newState[id]; // 初期値は undefined で Open 状態なので、false で Close 状態にする
    setItemToLocalStorage(TOGGLE_NAVIGATION_STORAGE_KEY, newState);
    setState(newState);
  };

  return [state, setToggleNavigationState] as const;
};

const TOGGLE_NAVIGATION_STORAGE_KEY = 'v1/components/layout/toggle-navigation';

export { useToggleNavigationItem };
