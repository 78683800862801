import React from 'react';
import { useHandleBlockingError } from '~/common/hooks/use-handle-blocking-error';

type GlobalErrorHandlerProps = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

function GlobalBlockingErrorHandler({ error, errorInfo }: GlobalErrorHandlerProps): JSX.Element {
  useHandleBlockingError({ error, errorInfo });

  return <></>;
}

export { GlobalBlockingErrorHandler };
