import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * ルーティング時にアプリの最新バージョンがリリースされているかを調べて、リリースされていたら画面全体をリロードするカスタムフック
 * 参考: https://qiita.com/github0013@github/items/eac2e744fb75ff564585
 */
const useReloadBeforeRoutingWhenNewAppIsReleased = () => {
  const { basePath, events } = useRouter();

  const checkNewAppIsReleased = useCallback((): boolean => {
    // ビルドIDを取得
    const nextDataDom = document.querySelector('#__NEXT_DATA__');
    const buildId =
      nextDataDom && nextDataDom.textContent ? JSON.parse(nextDataDom.textContent).buildId : null;
    if (!buildId) {
      return false;
    }

    // Next.jsのサーバーに現在のビルドIDでマニフェストjsを問い合わせる。このときキャッシュを効かせないようにする
    const request = new XMLHttpRequest();
    request.open('HEAD', `${basePath}/_next/static/${buildId}/_buildManifest.js`, false);
    request.setRequestHeader('Pragma', 'no-cache');
    request.setRequestHeader('Cache-Control', 'no-cache');
    request.setRequestHeader('If-Modified-Since', 'Thu, 01 Jun 1970 00:00:00 GMT');

    try {
      request.send(null);
    } catch (e) {
      // 400番台の時はエラーを投げない
      if (request.status >= 400 && request.status < 500) {
        throw e;
      }
    }

    // 404の場合はビルドIDが古くなったということになり、最新のバージョンがリリースされていることになる
    return request.status === 404;
  }, [basePath]);

  const reloadWhenNewAppIsReleased = useCallback(() => {
    if (checkNewAppIsReleased()) {
      document.location.reload();
    }
  }, [checkNewAppIsReleased]);

  useEffect(() => {
    // ルーティング時にリロードイベントを追加
    events.on('routeChangeComplete', reloadWhenNewAppIsReleased);

    return () => {
      // コンポーネント破棄時にルーティング時にリロードイベントの登録を破棄
      events.off('routeChangeComplete', reloadWhenNewAppIsReleased);
    };
  }, [reloadWhenNewAppIsReleased, events]);

  return { checkNewAppIsReleased };
};

export { useReloadBeforeRoutingWhenNewAppIsReleased };
