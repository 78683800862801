import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Auth0Provider } from '@auth0/auth0-react';
import { env } from '~/app/environment';

type Props = {
  children?: ReactNode;
};

const AuthProvider: React.FC<Props> = React.memo(function AuthWrapper({ children }) {
  const { replace } = useRouter();
  const environment = env();
  return (
    <>
      {environment && (
        <Auth0Provider
          domain={environment.auth0.domain}
          clientId={environment.auth0.client_id}
          authorizationParams={{
            redirect_uri: environment.auth0.redirect_uri,
            audience: environment.auth0.audience,
          }}
          // 注意：IdP-initiated SSO ログイン時、SP 認証済みで IdP からリダイレクトされた場合には実行されない
          onRedirectCallback={(appState) => {
            const returnTo =
              appState?.returnTo && !appState?.returnTo.startsWith('/login/callback')
                ? appState.returnTo
                : '/';
            replace(returnTo); // history に残らないように replace で遷移する
          }}
        >
          {children}
        </Auth0Provider>
      )}
    </>
  );
});

export { AuthProvider };
