import { useAuth0, User } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { LoginUser } from './login-user';
import { env } from '~/app/environment';
import { Role } from '~/common/providers/auth/role';
import { useSafeState } from '~/common/hooks/use-safe-state';

const convertToLoginUser = (user: User): LoginUser | undefined => {
  const environment = env();
  if (!environment || !user) return;
  const userObj = Object.create({});

  Object.entries(user)
    .map((data) => {
      data[0] = data[0].replace(`${environment.auth0.audience}/`, '');
      return data;
    })
    .forEach((data: [string, unknown]) => {
      const [dataKey, dataValue] = data;
      userObj[dataKey] = dataValue;
    });
  return userObj;
};

export const useLoginUser = (): LoginUser | undefined => {
  const { user } = useAuth0();

  const [loginUser, setLoginUser] = useSafeState<LoginUser | undefined>(
    user ? convertToLoginUser(user) : undefined,
  );

  useEffect(() => {
    const newLoginUser = user ? convertToLoginUser(user) : undefined;

    if (loginUser?.userInfo.userId !== newLoginUser?.userInfo.userId) {
      setLoginUser(newLoginUser);
    }
  }, [user, loginUser, setLoginUser]);

  return loginUser;
};

export const useMyRole = (): Role | undefined => {
  const loginUser = useLoginUser();
  return loginUser?.userInfo.role;
};
