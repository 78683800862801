import React, { ForwardedRef, useCallback, ReactNode } from 'react';
import {
  ContentLocation,
  ContentLocationGetter,
  Popover as TinyPopover,
  PopoverState,
} from 'react-tiny-popover';
import styles from './Popover.module.css';

type PopoverProps = {
  show: boolean;
  setShow: (current: boolean) => void;
  popover: ReactNode;
  padding?: number;
  align?: PopoverAlign;
  position?: PopoverPosition;
  positions?: PopoverPosition[]; // 優先度を全てコントロールする
  parentElement?: HTMLElement; // HTMLElementの型でparentElementを渡した場合はそのDOM要素を起点にポップオーバーを表示する
  children?: JSX.Element; // childrenを渡した場合はchildrenを起点にポップオーバーを表示する
  onClickOutside?: () => void;
};

type PopoverPosition = 'left' | 'right' | 'top' | 'bottom';
type PopoverAlign = 'start' | 'center' | 'end';

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

const Popover = React.forwardRef(
  (
    {
      show,
      setShow,
      popover,
      padding = 4, // var(--spacing-2) 相当
      position = 'bottom',
      positions,
      align = 'start',
      children,
      onClickOutside,
      parentElement,
    }: PopoverProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const getContentLocation: ContentLocationGetter = useCallback(
      (popoverState: PopoverState) => {
        return getContentLocationByWindowHeightAndParentElementRect(
          window.innerHeight,
          popoverState,
          parentElement?.getBoundingClientRect(),
        );
      },
      [parentElement],
    );
    return (
      <TinyPopover
        isOpen={show}
        padding={padding}
        positions={positions ?? [position, 'left', 'top', 'right', 'bottom']} // preferred positions by priority
        align={align}
        containerClassName={styles['popover-container']}
        contentLocation={parentElement && getContentLocation}
        ref={ref}
        content={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {popover}
          </div>
        }
        onClickOutside={() => {
          if (onClickOutside) {
            onClickOutside();
          } else {
            setShow(false);
          }
        }}
      >
        {children ? children : <div />}
      </TinyPopover>
    );
  },
);

/**
 * 表示位置に対してポップオーバーの位置を計算する関数
 * @param windowHeight 画面全体の高さ
 * @param popoverState react-tiny-popoverが提供する現時点でのポップオーバーのstate
 * @param parentElementRect ポップオーバーを表示位置の基準となる要素の位置と大きさ
 */
const getContentLocationByWindowHeightAndParentElementRect = (
  windowHeight: number,
  popoverState: PopoverState,
  parentElementRect?: Rect,
): ContentLocation => {
  const top = parentElementRect?.top ?? 0;

  // parentElementRect の取得が上手くいかない場合、画面外に表示する
  if (top === 0) {
    return {
      top: windowHeight,
      left: 0,
    };
  }

  // ポップオーバーを下向きに表示する余白（画面の縦幅 - 表示位置の高さ）がポップオーバーの大きさに対して足りないときに（ポップオーバーの高さ - 表示位置基準の要素の縦幅）分上にずらす
  // 要するに下の余白が足りないから上の余白に表示するための位置を計算している
  const heightOffset =
    popoverState.popoverRect.height > windowHeight - top
      ? popoverState.popoverRect.height - (parentElementRect?.height ?? 0)
      : 0;

  return {
    top: top - heightOffset,
    // 右側に表示したいので表示位置の要素の横幅分右にずらす
    left: (parentElementRect?.left ?? 0) + (parentElementRect?.width ?? 0),
  };
};

Popover.displayName = 'Popover';

export { Popover, getContentLocationByWindowHeightAndParentElementRect };
export type { PopoverPosition, PopoverAlign, Rect };
