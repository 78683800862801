import React, { ReactElement, ReactNode, useState } from 'react';
import { css } from '@emotion/react';
import { Popover, PopoverPosition, PopoverAlign } from '~/common/components/popover';
import { Span } from '~/common/components/span';

type TooltipProps = {
  message?: string | ReactNode;
  position?: PopoverPosition;
  children?: ReactElement;
  delay?: number;
  maxWidth?: number;
  align?: PopoverAlign;
};

function Tooltip({
  message,
  children,
  position = 'top',
  delay,
  maxWidth,
  align,
}: TooltipProps): ReactNode {
  const [isShow, setIsShow] = useState(false);

  const tooltipPanel = (
    <div css={[styles.panel, maxWidth ? styles.maxWidth(maxWidth) : undefined]}>
      <Span level={2} color={'white'} prewrap>
        {message}
      </Span>
    </div>
  );

  const show = () => {
    if (!message) return;
    if (!delay) {
      setIsShow(true);
      return;
    }
    timer = window.setTimeout(() => setIsShow(true), delay);
  };

  const hide = () => {
    clearTimeout(timer);
    setIsShow(false);
  };

  let timer: number;
  return (
    <Popover
      show={isShow && !!message}
      setShow={setIsShow}
      popover={tooltipPanel}
      position={position}
      align={align}
    >
      <div
        onPointerEnter={show}
        onPointerLeave={hide}
        onBlur={hide}
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          {children}
        </div>
      </div>
    </Popover>
  );
}

const styles = {
  panel: css`
    border-radius: 4px;
    padding: var(--spacing-1) var(--spacing-3);
    background-color: var(--color-neutral-9);
  `,
  maxWidth: (maxWidth: number) => css`
    max-width: ${maxWidth}px;
  `,
};

export { Tooltip };
export type { TooltipProps };
