import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { setDatadogLogsUserInfo } from '~/common/hooks/set-datadog-user-info';
import {
  getDatadogAlertLevel,
  maskHttpError,
  toHttpError,
} from '~/common/hooks/http-error/http-error';
import { env } from '~/app/environment';

const useSendErrorToDataDog = () => {
  const loginUser = useLoginUser();

  const sendErrorToDataDog = useCallback(
    async (e: Error) => {
      const maskedError = maskHttpError(e);

      setDatadogLogsUserInfo(loginUser);
      const httpError = await toHttpError(maskedError);

      if (env()?.production || env()?.staging) {
        const datadogAlertLevel = getDatadogAlertLevel(httpError);
        datadogLogs.logger.log(
          maskedError.message ?? '',
          {
            error: maskedError,
          },
          datadogAlertLevel,
        );
      }
    },
    [loginUser],
  );

  return { sendErrorToDataDog };
};

export { useSendErrorToDataDog };
