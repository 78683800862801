import React, { ReactNode, useRef } from 'react';
import { css } from '@emotion/react';
import { useEffectOnce } from 'react-use';

type Props = {
  autoFocus?: boolean;
  children?: ReactNode;
};

/**
 * メニューは、一時的に選択肢のリストをユーザーに提示するコンポーネントです。
 * ユーザーがボタン、アクション、またはその他のコントロールを操作したときに表示されます。
 * メニューの内容は、ユーザーのニーズに合ったものを表示します。
 */
const Menu: React.FC<Props> = React.memo(function Menu({ autoFocus = true, children }) {
  const focusRef = useRef<HTMLDivElement | null>(null);
  useEffectOnce(() => {
    if (autoFocus) {
      focusRef.current?.focus();
    }
  });

  return (
    // Tab で Menu が開いた際に focus をあてる
    <div css={styles.menu} role="menu" tabIndex={-1} ref={focusRef}>
      {children}
    </div>
  );
});

const styles = {
  menu: css`
    flex-direction: column;
    max-width: 360px;
    border-radius: 4px;
  `,
};

export { Menu };
