import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { ScrollContainer } from '~/common/components/scroll-container';
import { AdminSideBar } from '~/common/components/admin-layout/admin-sidebar/AdminSidebar';
import { MainTemplate } from '~/common/components/main-template';

type Props = {
  children: ReactNode;
  currentUrl: string;
};

const AdminLayoutBody: React.FC<Props> = React.memo(function AdminLayoutBody({
  children,
  currentUrl,
}) {
  return (
    <div css={styles.container}>
      <div css={styles.sideBar}>
        <AdminSideBar currentUrl={currentUrl} />
      </div>
      <div css={styles.main}>
        <div css={styles.mainAndDrawer} id={'drawer'}>
          <ScrollContainer css={styles.mainInner}>
            <MainTemplate>{children}</MainTemplate>
          </ScrollContainer>
          {/* ドロワーが存在しないときに右の余白16pxつくるためのdiv */}
          <div />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css`
    display: block;
    flex-grow: 1;
    position: relative;
  `,

  main: css`
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 16px;
    position: absolute;
    right: 0;
    width: calc(100% - 200px);
    height: 100%;
  `,

  mainInner: css`
    flex-grow: 1;
    padding: var(--spacing-5) 1px var(--spacing-5) var(--spacing-5);
  `,

  sideBar: css`
    flex-shrink: 0;
    width: 200px;
    filter: drop-shadow(0 0 4px rgba(23, 23, 37, 0.16));
    transition-duration: 0.5s;
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 100;
  `,

  mainAndDrawer: css`
    overflow: hidden;
    display: flex;
    flex-grow: 1;

    > div:last-child {
      margin-left: var(--spacing-5);
    }
  `,
};

export { AdminLayoutBody };
