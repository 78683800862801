import React, { HTMLAttributeAnchorTarget, ReactElement, ReactNode } from 'react';
import { css } from '@emotion/react';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { Icon, IconSize } from '~/common/components/icon';

type Props = {
  href: string;
  target?: HTMLAttributeAnchorTarget;
  showIcon?: boolean;
  icon?: ReactElement;
  iconSize?: IconSize;
  children: ReactNode;
};

const LabsLink: React.FC<Props> = React.memo(function LabsLink({
  children,
  href,
  target,
  showIcon = true,
  icon = <ExternalLinkIcon />,
  iconSize = 's',
}) {
  return (
    <a href={href} css={styles.link} target={target} rel="noopener noreferrer">
      {children}
      {showIcon && (
        <Icon size={iconSize} color={'dark'}>
          {icon}
        </Icon>
      )}
    </a>
  );
});

const styles = {
  link: css`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-1);

    & > * {
      width: 100%;
    }
  `,
};

export { LabsLink };
