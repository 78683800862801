import { memo } from 'react';
import { css } from '@emotion/react';
import { NavigationStatus } from '~/common/components/layout/navigation/navigation-control';

type NavigationHeaderProps = {
  navigationStatus: NavigationStatus;
};

const NavigationHeader = memo(function NavigationHeader({
  navigationStatus,
}: NavigationHeaderProps) {
  const isClose = navigationStatus === 'CLOSE';
  return (
    <div css={styles.container}>
      <LogoMark />
      <LogoType isClose={isClose} />
    </div>
  );
});

// ロゴマーク（クジラのマークのみ）
const LogoMark = () => (
  <svg
    width="29"
    height="20"
    viewBox="0 0 72 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={styles.logoMark}
  >
    <path
      d="M27.8401 16.401C29.1444 16.401 30.2029 15.3393 30.2029 14.035C30.2029 12.7308 29.1412 11.6722 27.8401 11.6722C26.539 11.6722 25.4741 12.7339 25.4741 14.035C25.4741 15.3362 26.5358 16.401 27.8401 16.401Z"
      fill="white"
    />
    <path
      d="M32.7453 33.4068C28.0953 31.2709 24.3652 27.5345 22.2387 22.875C21.9268 22.1977 21.6496 21.4952 21.4164 20.7926C18.7827 15.0242 13.5499 10.6578 7.40659 9.10779C4.53343 12.3842 2.95508 16.59 2.95508 20.9564C2.95508 23.9871 3.72378 26.9832 5.17296 29.6169C5.82509 30.7006 6.56859 31.7623 7.37509 32.7673C10.5853 36.7715 14.81 39.8494 19.5892 41.6735C22.8562 42.9147 26.2995 43.5448 29.8248 43.5448C31.1858 43.5448 32.5625 43.4472 33.9172 43.255C32.8461 41.3112 32.2822 39.1091 32.2822 36.8817C32.2822 35.7066 32.4365 34.5378 32.7453 33.4068V33.4068Z"
      fill="white"
    />
    <path
      d="M44.7514 39.3737C47.8262 37.5055 50.4915 35.1144 52.6747 32.2664C49.6251 34.1157 46.1471 35.1679 42.5714 35.3255C43.5354 36.5447 44.2663 37.9056 44.7514 39.3769V39.3737Z"
      fill="white"
    />
    <path
      d="M58.4525 8.55649C56.8931 11.2501 57.268 14.7155 59.3567 17.0185C55.4754 21.2904 49.9527 23.7351 44.1844 23.7351C43.3653 23.7351 42.5462 23.6879 41.7522 23.5933C41.8625 22.7175 41.9161 21.8291 41.9161 20.9565C41.9161 9.40079 32.5121 0 20.9565 0C9.40079 0 0 9.40079 0 20.9565C0 24.3211 0.774998 27.5345 2.30294 30.5116C3.22916 32.1278 4.30345 33.6715 5.5006 35.1018L5.5132 35.1144C7.0821 36.9857 8.87153 38.6806 10.8311 40.1487C12.1574 45.3689 16.4545 49.225 21.785 49.9779L21.8638 49.9874L21.9016 49.9181C22.6136 48.6327 23.1019 47.2497 23.3539 45.8005C27.4589 46.6543 31.7277 46.6858 35.8421 45.895C37.9056 48.1066 40.6748 49.5558 43.6519 49.9748L43.7307 49.9842L43.7685 49.9149C44.8491 47.968 45.4193 45.7533 45.4193 43.5102C45.4193 43.1479 45.4036 42.7761 45.3721 42.4012C47.4198 41.2482 49.3416 39.8652 51.0837 38.2931C56.786 33.1422 60.4121 26.1326 61.3162 18.537C62.9292 19.397 64.8226 19.6144 66.5837 19.1418C68.4487 18.6409 70.005 17.4469 70.9722 15.7741L71.0888 15.5756L58.5754 8.35171L58.4588 8.55019L58.4525 8.55649ZM27.8401 11.6691C29.1444 11.6691 30.2029 12.7308 30.2029 14.0319C30.2029 15.333 29.1412 16.3978 27.8401 16.3978C26.539 16.3978 25.4741 15.3361 25.4741 14.0319C25.4741 12.7276 26.5358 11.6691 27.8401 11.6691ZM29.828 43.5448C26.3027 43.5448 22.8593 42.9147 19.5923 41.6735C14.8132 39.8526 10.5885 36.7715 7.37824 32.7673C6.57173 31.7623 5.83139 30.7006 5.17611 29.6169C3.72377 26.9832 2.95823 23.9903 2.95823 20.9565C2.95823 16.59 4.53973 12.3842 7.40974 9.10781C13.5499 10.6547 18.7858 15.0211 21.4196 20.7926C21.6527 21.4952 21.9299 22.1977 22.2418 22.8751C24.3683 27.5345 28.0984 31.2709 32.7484 33.4068C32.4428 34.5378 32.2853 35.7035 32.2853 36.8817C32.2853 39.1091 32.8492 41.3112 33.9204 43.255C32.5657 43.4472 31.1921 43.5448 29.828 43.5448ZM44.7514 39.3768C44.2663 37.9056 43.5354 36.5446 42.5714 35.3254C46.1502 35.1711 49.6251 34.1188 52.6747 32.2664C50.4883 35.1175 47.8262 37.5055 44.7514 39.3737V39.3768Z"
      fill="#2452C8"
    />
  </svg>
);

// ロゴタイプ（Loglassの文字）
const LogoType = memo(function LogoType({ isClose }: { isClose: boolean }) {
  return (
    <svg
      width="63"
      height="17"
      viewBox="0 0 158 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={isClose ? [styles.logoType, styles.logoTypeHidden] : styles.logoType}
    >
      <path d="M0.61377 31.4588V0.364319H8.16843V24.9154H20.203V31.4588H0.61377Z" fill="#272C30" />
      <path
        d="M34.8838 32.1613C33.2519 32.1613 31.6799 31.8809 30.2055 31.3264C28.7342 30.7751 27.4363 29.9844 26.3399 28.9762C25.2467 27.9713 24.3678 26.73 23.7251 25.2871C23.0824 23.8442 22.7579 22.2123 22.7579 20.4324C22.7579 18.6524 23.0824 17.0299 23.7251 15.6028C24.3646 14.1757 25.2436 12.9502 26.3399 11.9578C27.4331 10.9654 28.7342 10.1967 30.2055 9.67375C31.6767 9.14763 33.2488 8.883 34.8838 8.883C36.5189 8.883 38.1256 9.14763 39.5842 9.67375C41.0397 10.1967 42.3408 10.9654 43.4498 11.9578C44.5555 12.9502 45.444 14.1757 46.0866 15.6028C46.7293 17.0331 47.0538 18.6587 47.0538 20.4324C47.0538 22.206 46.7293 23.8411 46.0866 25.2871C45.444 26.73 44.5587 27.9713 43.4498 28.9762C42.3408 29.9844 41.0397 30.7751 39.5842 31.3264C38.1256 31.8809 36.5441 32.1613 34.8838 32.1613ZM34.8838 14.8562C34.0395 14.8562 33.2866 15.02 32.6439 15.3382C32.0012 15.6595 31.4625 16.0785 31.0403 16.5889C30.6182 17.0992 30.2937 17.6978 30.0732 18.3688C29.8558 19.0399 29.7455 19.733 29.7455 20.4324C29.7455 21.1318 29.8558 21.828 30.0732 22.4959C30.2905 23.1638 30.6245 23.7781 31.0624 24.32C31.5003 24.8587 32.0453 25.2966 32.688 25.6148C33.3275 25.9361 34.0805 26.0968 34.9279 26.0968C35.7754 26.0968 36.5283 25.933 37.1679 25.6148C37.8105 25.2934 38.3556 24.8587 38.7935 24.32C39.2314 23.7812 39.5622 23.1669 39.7827 22.4959C40.0001 21.828 40.1135 21.1318 40.1135 20.4324C40.1135 19.733 39.9938 19.0399 39.7606 18.3688C39.5275 17.6978 39.1873 17.0992 38.7494 16.5889C38.3115 16.0785 37.7664 15.6595 37.1238 15.3382C36.4811 15.0168 35.7281 14.8562 34.8838 14.8562V14.8562Z"
        fill="#272C30"
      />
      <path
        d="M61.4669 42C59.5451 42 57.5415 41.7039 55.5158 41.121C53.4901 40.5382 51.7101 39.6813 50.2231 38.5724L53.824 33.4341C54.8731 34.3666 56.0482 35.0691 57.3147 35.5196C58.5843 35.9701 59.8476 36.2001 61.0699 36.2001C63.1681 36.2001 64.7433 35.6393 65.7483 34.5304C66.7533 33.4215 67.2636 31.9313 67.2636 30.0946V28.6297L66.9486 29.0014C66.2996 29.7733 65.4427 30.4034 64.4094 30.8822C63.2721 31.4084 61.9584 31.673 60.4997 31.673C58.8363 31.673 57.3462 31.3705 56.064 30.772C54.7817 30.1765 53.6823 29.3543 52.7907 28.3336C51.9023 27.316 51.2155 26.1094 50.7492 24.7547C50.283 23.4 50.0467 21.9446 50.0467 20.4292C50.0467 18.9139 50.283 17.4521 50.7492 16.0817C51.2155 14.7112 51.9023 13.4857 52.7907 12.4366C53.6791 11.3876 54.7786 10.537 56.064 9.91002C57.3462 9.2831 58.8237 8.9649 60.4556 8.9649C61.8229 8.9649 63.1524 9.24529 64.4094 9.79976C65.5341 10.2975 66.476 11.0536 67.2038 12.0523L67.5251 12.4934V8.96806H74.3331V29.3952C74.3331 31.5218 74.0244 33.3994 73.4101 34.9746C72.7989 36.5467 71.9262 37.8698 70.8173 38.9063C69.7083 39.9396 68.3411 40.7241 66.7533 41.2345C65.1623 41.7448 63.3823 42.0031 61.4606 42.0031L61.4669 42ZM62.1694 14.8562C61.3535 14.8562 60.6226 15.0042 59.9957 15.2941C59.3687 15.587 58.83 15.984 58.3921 16.4786C57.9542 16.9764 57.614 17.5655 57.3808 18.2365C57.1477 18.9044 57.028 19.629 57.028 20.3883C57.028 21.1475 57.1477 21.828 57.3808 22.4959C57.614 23.1669 57.9542 23.7655 58.3921 24.2758C58.83 24.7862 59.3687 25.1989 59.9957 25.5045C60.6226 25.8101 61.3535 25.9645 62.1694 25.9645C62.9854 25.9645 63.732 25.8101 64.3873 25.5045C65.0426 25.1989 65.6034 24.7925 66.057 24.2979C66.5107 23.8033 66.8635 23.211 67.1124 22.54C67.3613 21.8689 67.4842 21.1444 67.4842 20.3883C67.4842 19.6322 67.3581 18.9517 67.1124 18.2806C66.8635 17.6096 66.5012 17.011 66.035 16.5007C65.5687 15.9903 65.0079 15.5839 64.3653 15.2941C63.7226 15.0011 62.9854 14.8562 62.1694 14.8562V14.8562Z"
        fill="#272C30"
      />
      <path d="M87.1962 0.364319H79.8621V31.4619H87.1962V0.364319Z" fill="#272C30" />
      <path
        d="M99.8451 31.9849C98.9693 31.9849 98.0556 31.8652 97.1231 31.632C96.1906 31.3989 95.3274 31.0146 94.5524 30.4916C93.7805 29.9686 93.1379 29.2566 92.6401 28.384C92.1455 27.5113 91.8935 26.4181 91.8935 25.1328C91.8935 23.5576 92.3282 22.2722 93.1883 21.3113C94.0483 20.3505 95.1699 19.5975 96.5277 19.0714C97.8824 18.5453 99.4135 18.1924 101.074 18.016C102.671 17.849 104.252 17.7608 105.771 17.7514H105.951V17.3985C105.951 16.3054 105.576 15.5241 104.81 15.0042C104.051 14.4939 103.106 14.2355 102 14.2355C100.979 14.2355 99.9805 14.4561 99.0354 14.894C98.0871 15.3319 97.268 15.8643 96.597 16.4755L92.952 12.5658C94.2342 11.3435 95.7495 10.411 97.4539 9.79978C99.1583 9.1886 100.926 8.87671 102.702 8.87671C104.479 8.87671 106.111 9.10669 107.381 9.5572C108.647 10.0109 109.69 10.7197 110.478 11.6648C111.265 12.6131 111.848 13.8228 112.214 15.2657C112.579 16.7086 112.762 18.4445 112.762 20.4261V31.4493H106.174V28.9605L105.85 29.411C105.298 30.1766 104.514 30.7877 103.515 31.2288C102.394 31.7234 101.159 31.9754 99.8482 31.9754L99.8451 31.9849ZM105.248 21.9257C104.47 21.9257 103.663 21.9635 102.854 22.0359C102.053 22.1084 101.329 22.2502 100.702 22.4518C100.075 22.6566 99.5489 22.959 99.1425 23.3528C98.7298 23.7497 98.5282 24.2601 98.5282 24.9122C98.5282 25.3218 98.6227 25.6746 98.8149 25.9676C99.0039 26.2575 99.2465 26.4937 99.5395 26.6702C99.8325 26.8466 100.17 26.9695 100.551 27.0451C100.929 27.1175 101.297 27.1553 101.65 27.1553C103.109 27.1553 104.237 26.7489 105.012 25.9487C105.784 25.1485 106.174 24.0459 106.174 22.6755V21.9288H105.251L105.248 21.9257Z"
        fill="#272C30"
      />
      <path
        d="M125.858 32.1613C124.081 32.1613 122.314 31.8746 120.609 31.3044C118.902 30.7342 117.462 29.8867 116.328 28.7778L120.37 24.5185C121.013 25.2178 121.81 25.8101 122.742 26.2764C123.675 26.7426 124.68 26.9789 125.729 26.9789C126.312 26.9789 126.898 26.8371 127.465 26.5631C128.032 26.2858 128.322 25.7912 128.322 25.0918C128.322 24.3924 127.966 23.9199 127.266 23.5985C126.567 23.2772 125.458 22.937 123.971 22.5873C123.208 22.4108 122.443 22.1746 121.687 21.8847C120.928 21.5949 120.241 21.1948 119.645 20.7002C119.047 20.2056 118.552 19.5849 118.174 18.854C117.796 18.1263 117.604 17.241 117.604 16.2203C117.604 14.9097 117.884 13.7787 118.439 12.8588C118.99 11.9421 119.715 11.1828 120.59 10.5968C121.463 10.014 122.446 9.57926 123.511 9.30202C124.576 9.02479 125.647 8.88617 126.696 8.88617C128.328 8.88617 129.944 9.1445 131.507 9.65487C133.066 10.1652 134.405 10.9402 135.482 11.961L131.529 16.047C130.946 15.4926 130.25 15.02 129.465 14.642C128.678 14.2639 127.836 14.0717 126.961 14.0717C126.29 14.0717 125.663 14.204 125.096 14.4655C124.528 14.727 124.239 15.187 124.239 15.8265C124.239 16.466 124.557 16.8819 125.184 17.1434C125.811 17.4049 126.838 17.701 128.236 18.0223C129.052 18.1988 129.887 18.4476 130.719 18.769C131.551 19.0903 132.31 19.5188 132.981 20.0417C133.649 20.5647 134.197 21.2168 134.607 21.9729C135.016 22.7322 135.221 23.6489 135.221 24.6949C135.221 26.0653 134.941 27.2404 134.386 28.1855C133.832 29.1338 133.101 29.9088 132.212 30.4916C131.324 31.0744 130.313 31.5029 129.204 31.7644C128.095 32.0259 126.973 32.1582 125.867 32.1582L125.858 32.1613Z"
        fill="#272C30"
      />
      <path
        d="M147.662 32.1613C145.885 32.1613 144.118 31.8746 142.413 31.3044C140.709 30.7373 139.266 29.8867 138.132 28.7778L142.174 24.5185C142.817 25.2178 143.614 25.8101 144.546 26.2764C145.479 26.7426 146.484 26.9789 147.533 26.9789C148.115 26.9789 148.701 26.8371 149.269 26.5631C149.836 26.2858 150.125 25.7912 150.125 25.0918C150.125 24.3924 149.769 23.9199 149.073 23.5985C148.374 23.2772 147.265 22.937 145.778 22.5873C145.019 22.4108 144.25 22.1746 143.494 21.8847C142.735 21.5949 142.048 21.1948 141.452 20.7002C140.854 20.2056 140.359 19.5849 139.981 18.854C139.603 18.1263 139.411 17.241 139.411 16.2203C139.411 14.9097 139.691 13.7787 140.246 12.8588C140.797 11.9421 141.522 11.1828 142.398 10.5968C143.27 10.014 144.253 9.57926 145.318 9.30202C146.383 9.02479 147.454 8.88617 148.503 8.88617C150.135 8.88617 151.751 9.1445 153.314 9.65487C154.873 10.1652 156.209 10.9402 157.289 11.961L153.336 16.047C152.753 15.4926 152.057 15.02 151.272 14.642C150.485 14.2639 149.643 14.0717 148.768 14.0717C148.097 14.0717 147.47 14.204 146.903 14.4655C146.336 14.727 146.046 15.187 146.046 15.8265C146.046 16.466 146.364 16.8819 146.991 17.1434C147.618 17.4049 148.645 17.701 150.044 18.0223C150.86 18.1988 151.694 18.4476 152.526 18.769C153.358 19.0903 154.12 19.5188 154.788 20.0417C155.459 20.5679 156.004 21.2168 156.414 21.9729C156.823 22.7322 157.028 23.6489 157.028 24.6949C157.028 26.0653 156.748 27.2404 156.193 28.1855C155.639 29.1338 154.908 29.9088 154.019 30.4916C153.131 31.0744 152.12 31.5029 151.011 31.7644C149.902 32.0259 148.78 32.1582 147.671 32.1582L147.662 32.1613Z"
        fill="#272C30"
      />
    </svg>
  );
});

const styles = {
  container: css`
    position: relative;
    height: 56px;
    display: flex;
    align-items: flex-end;
    padding: var(--spacing-5) var(--spacing-4);
    border-bottom: var(--border-width-s) solid var(--color-neutral-4);
  `,
  logoMark: css`
    position: absolute;
  `,
  logoType: css`
    visibility: visible;
    opacity: 1;
    transition:
      opacity 300ms,
      left 300ms;
    position: absolute;
    left: 44px; /* container-padding + logoMark + gap */
  `,
  logoTypeHidden: css`
    visibility: hidden;
    opacity: 0;
    left: 36px; /* slide animation */
  `,
};

export { NavigationHeader };
export type { NavigationHeaderProps };
