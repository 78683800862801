import React, { ReactNode } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { SWRResponse } from 'swr';
import { env } from '~/app/environment';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { useFetch } from '~/common/hooks/use-fetch';
import { ZendeskControllerApi } from '~/common/api';

const useZendeskJwt = (): SWRResponse<string | void, Error> => {
  const user = useLoginUser();
  return useFetch('/api/zendesk/jwt', (reqOps) => {
    return new ZendeskControllerApi()
      .jwt(reqOps)
      .then((response) => response.data.jwt)
      .catch((e) => {
        console.info(e); // Zendeskの処理が失敗してもアプリをフリーズさせたくないため、通知だけしてエラーは投げない
        console.info(
          `zendeskへの認証に使うjwtの取得に失敗しました。tenantId: ${user?.tenantInfo.tenantId}, userId: ${user?.userInfo.userId}`,
        );
      });
  });
};

type ZendeskWidgetProviderProps = {
  children?: ReactNode;
};

const ZendeskWidgetProvider = React.memo(function ZendeskWidgetProvider({
  children,
}: ZendeskWidgetProviderProps): JSX.Element {
  const { data: zendeskJwt, isValidating: isJwtLoading } = useZendeskJwt();
  const zendeskWidgetKey = env()?.zendesk.widgetKey ?? '';

  if (isJwtLoading) {
    return <>{children}</>;
  }

  const onWidgetLoaded = () => {
    if (typeof ZendeskAPI !== 'function') {
      return;
    }

    ZendeskAPI('messenger', 'loginUser', function (callback: (jwt: string) => void) {
      if (zendeskJwt) callback(zendeskJwt);
    });
    ZendeskAPI('messenger', 'close');
  };

  return (
    <>
      {zendeskWidgetKey && (
        <Zendesk defer zendeskKey={zendeskWidgetKey} onLoaded={() => onWidgetLoaded()} />
      )}
      {children}
    </>
  );
});

const openZendeskWidget = () => {
  ZendeskAPI('messenger', 'open');
};

export { ZendeskWidgetProvider, openZendeskWidget };
