import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';

type DrawerBodyProps = PropsWithChildren<{
  className?: string;
}>;

const DrawerBody = React.memo(function DrawerBody({ children, className }: DrawerBodyProps) {
  return (
    <div css={styles.body} className={className}>
      {children}
    </div>
  );
});

const styles = {
  body: css`
    padding: var(--spacing-5);
    width: 100%;
    height: 100%;
    overflow-y: auto;
  `,
};

export { DrawerBody };
