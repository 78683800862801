import { isClientSide } from '~/common/utils/is-client-side';

type Environment = {
  production: boolean;
  demo: boolean;
  staging: boolean;
  apiUrlPrefix: string;
  gpiClientId: string;
  auth0: {
    domain: string;
    client_id: string;
    redirect_uri: string;
    audience: string;
  };
  adminApiUrlPrefix: string;
  adminAuth0: {
    domain: string;
    client_id: string;
    redirect_uri: string;
    audience: string;
    organization: string;
  };
  datadog: {
    site?: string;
    logs: {
      clientToken?: string;
    };
    rum: {
      applicationId?: string;
      clientToken?: string;
    };
  };
  googleTagManager: {
    gtm_id: string;
    gtm_auth?: string;
    gtm_preview?: string;
  };
  zendesk: {
    widgetKey?: string;
  };
};
type EnvironmentName = 'stg' | 'dem' | 'prd';

const environment: Record<string, Environment> = {
  'https://loglass.com': {
    production: true,
    demo: false,
    staging: false,
    apiUrlPrefix: 'https://loglass-api.loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'prd-loglass.auth0.com',
      client_id: 'hLYyuzzSKmSNmBPs2R2tCZd170cGx4YM',
      redirect_uri: 'https://loglass.com/login/callback',
      audience: 'https://prd-loglass-api.loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api.loglass.com',
    adminAuth0: {
      domain: 'prd-loglass.auth0.com',
      client_id: '0lwLZWif6QTZJlF8oUT2HwZCggy6J4IG',
      redirect_uri: 'https://loglass.com/admin/login/callback',
      audience: 'https://prd-loglass-admin-api.loglass.jp',
      organization: 'org_mxVywZzkPgdmIfoZ',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '391ebf34-a8f6-4ca7-b65a-8eba9c189f7b',
        clientToken: 'pub49a9faffae4977ed1631d518abfaea02',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'nfcRV20sNdYiP9Er4C3Wlu0sibEHVpcV',
      redirect_uri: 'https://demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'yVCXulU1nTyxMmSnD2okZleGh4tXbWrl',
      redirect_uri: 'https://demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: 'b78979f6-9411-4f21-ad89-f9eb35060c97',
    },
  },
  'https://www-testf.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testf.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'wI6fq9L02dNlaYMveKGV69GE5SLf7x8U',
      redirect_uri: 'https://www-testf.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testf.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: '8fV0xMvcfCHXhiNlkoT7ZPpF4VG8l6dy',
      redirect_uri: 'https://www-testf.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testp.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testp.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'z4YnfNmRfIwm5H1VYOdQ4owVEPmuXUGX',
      redirect_uri: 'https://www-testp.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testp.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'FDL9BNdeL0APj8lFP9AgP7yt75ZVPRsA',
      redirect_uri: 'https://www-testp.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testz.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testz.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: '4kegBDdHUUkV1khwYmDERAQVXaiSMcXZ',
      redirect_uri: 'https://www-testz.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testz.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'l0l9kiwkUWALQVAQ3vQXrlZNRIAqbZZW',
      redirect_uri: 'https://www-testz.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testh.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testh.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'VVCWscyMFDPOYESQlgMQmfEg3Vfs48Hx',
      redirect_uri: 'https://www-testh.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testh.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'GQPBFi6sUVlWLTrmONvcn1j6AxYOadA0',
      redirect_uri: 'https://www-testh.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testr.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testr.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'gLmXTUHVeS1l3wnk0iaFQH7kljQvSwLy',
      redirect_uri: 'https://www-testr.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testr.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'YhbSv4chOPjMkb6Ff5DZrvbNJYwbKSQh',
      redirect_uri: 'https://www-testr.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testc.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testc.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'Bx8BR1ZUxm6vvtEEukvDmMiolZSEcDpI',
      redirect_uri: 'https://www-testc.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testc.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'CTRNTMAU7e66apuRvjsznWxvPsH0S78G',
      redirect_uri: 'https://www-testc.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testb.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testb.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'i34LMnkebrO4yJqba5YgYDltI5NJKNDV',
      redirect_uri: 'https://www-testb.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testb.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'GjA5l8ONl8yqMcFz2KSP0fJZkCm6HtGr',
      redirect_uri: 'https://www-testb.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testo.demoglass.xyz': {
    production: false,
    demo: false,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testo.demoglass.xyz',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: '4sNDDIeyucsH73EHwl6wv2RA3tpFaLPY',
      redirect_uri: 'https://www-testo.demoglass.xyz/login/callback',
      audience: 'https://stg-loglass-api.loglass.jp',
    },
    adminApiUrlPrefix: 'https://admin-api-testo.demoglass.xyz',
    adminAuth0: {
      domain: 'stg-loglass.auth0.com',
      client_id: 'UkZS3tvcrkwa9HQNhGqm9Bldz4HKvSGH',
      redirect_uri: 'https://www-testo.demoglass.xyz/admin/login/callback',
      audience: 'https://stg-loglass-admin-api.loglass.jp',
      organization: 'org_eu8S68AzYwO59u81',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OcXotokAIz95AuOfSQYFcQ',
      gtm_preview: 'env-3',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://demo-loglass.com': {
    production: false,
    demo: true,
    staging: true,
    apiUrlPrefix: 'https://loglass-api.demo-loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'agrDaei7xWkjqmwzVMBjDuNmekZ1YiT7',
      redirect_uri: 'https://demo-loglass.com/login/callback',
      audience: 'https://dem-loglass-api.demo-loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api.demo-loglass.com',
    adminAuth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'YEfoAJGADbAMywY9Y95ruIal6JVyZrCC',
      redirect_uri: 'https://demo-loglass.com/admin/login/callback',
      audience: 'https://dem-loglass-admin-api.demo-loglass.com',
      organization: 'org_DsnOrgNOdUP194NX',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-sub.demo-loglass.com': {
    production: false,
    demo: true,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-extra.demo-loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'o6wIT8pUEY9qHhqIPTjyjTS5rLeCk1Gi',
      redirect_uri: 'https://www-sub.demo-loglass.com/login/callback',
      audience: 'https://dem-loglass-api.demo-loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api.demo-loglass.com',
    adminAuth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'AElImo69HhZ7iUqdivIrqQ1W1lS2n3hU',
      redirect_uri: 'https://www-sub.demo-loglass.com/admin/login/callback',
      audience: 'https://dem-loglass-admin-api.demo-loglass.com',
      organization: 'org_DsnOrgNOdUP194NX',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testo.demo-loglass.com': {
    production: false,
    demo: true,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testo.demo-loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'gLs3tWmPWs6R4lPull7nrmBsgp63Sx8W',
      redirect_uri: 'https://www-testo.demo-loglass.com/login/callback',
      audience: 'https://dem-loglass-api.demo-loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api-testo.demo-loglass.com',
    adminAuth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'grhxKmzj9Gin1oDpQJqklSV1lEFudJTF',
      redirect_uri: 'https://www-testo.demo-loglass.com/admin/login/callback',
      audience: 'https://dem-loglass-admin-api.demo-loglass.com',
      organization: 'org_DsnOrgNOdUP194NX',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testp.demo-loglass.com': {
    production: false,
    demo: true,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testp.demo-loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'Pj9tgwegteRnyQFutxnazpsegciQoOmj',
      redirect_uri: 'https://www-testp.demo-loglass.com/login/callback',
      audience: 'https://dem-loglass-api.demo-loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api-testp.demo-loglass.com',
    adminAuth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'cKMjdcQHYeGLAUUqVWSE5rI876jB5Tum',
      redirect_uri: 'https://www-testp.demo-loglass.com/admin/login/callback',
      audience: 'https://dem-loglass-admin-api.demo-loglass.com',
      organization: 'org_DsnOrgNOdUP194NX',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'https://www-testz.demo-loglass.com': {
    production: false,
    demo: true,
    staging: true,
    apiUrlPrefix: 'https://loglass-api-testz.demo-loglass.com',
    gpiClientId: '188891869490-qp7gfsg4iu56fmlq9ea0vok1t4nbdi4u.apps.googleusercontent.com',
    auth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: 'wpf26FgybaOxGNMiVYXTOWKQJPb2c2Dh',
      redirect_uri: 'https://www-testz.demo-loglass.com/login/callback',
      audience: 'https://dem-loglass-api.demo-loglass.com',
    },
    adminApiUrlPrefix: 'https://admin-api-testz.demo-loglass.com',
    adminAuth0: {
      domain: 'dem-loglass.us.auth0.com',
      client_id: '4wJq2kbbyVEp1oMqfE7863Frl7OP9ZJs',
      redirect_uri: 'https://www-testz.demo-loglass.com/admin/login/callback',
      audience: 'https://dem-loglass-admin-api.demo-loglass.com',
      organization: 'org_DsnOrgNOdUP194NX',
    },
    datadog: {
      site: 'datadoghq.com',
      logs: {
        clientToken: 'pub7a62578ff12a4f74af3e506b8c3cded6',
      },
      rum: {
        applicationId: '72e1f164-c3f2-4154-a8b7-17d652e29927',
        clientToken: 'pubbec0967716ce072064e12ade32a6000c',
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
  'http://localhost:8020': {
    production: false,
    demo: false,
    staging: false,
    apiUrlPrefix: 'http://localhost:8080',
    gpiClientId: '188891869490-lc2csdmcflun9tgep4u0a0h8i4tiat7a.apps.googleusercontent.com',
    auth0: {
      domain: 'local-loglass2.auth0.com',
      client_id: 'wRY7PRygGMLTqdb8RBIk2qw6YOd33BTn',
      redirect_uri: 'http://localhost:8020/login/callback',
      audience: 'https://local-loglass2-api.loglass.jp',
    },
    adminApiUrlPrefix: 'http://localhost:8081',
    adminAuth0: {
      domain: 'local-loglass2.auth0.com',
      client_id: 'ZMeHgFdw1Evk000PSooDIoutglknY8hv',
      redirect_uri: 'http://localhost:8020/admin/login/callback',
      audience: 'https://local-loglass2-admin-api.loglass.jp',
      organization: 'org_FMK2vaOjNKjkE8RV',
    },
    datadog: {
      site: undefined,
      logs: {
        clientToken: undefined,
      },
      rum: {
        applicationId: undefined,
        clientToken: undefined,
      },
    },
    googleTagManager: {
      gtm_id: 'GTM-KS8KKVP',
      gtm_auth: 'OKDMwJLRbyLT5r9UPrMsVg',
      gtm_preview: 'env-4',
    },
    zendesk: {
      widgetKey: '9af0ac35-65a8-45bc-9a68-b2ddf7eda859',
    },
  },
};

const defaultEnvironment: Environment = {
  production: false,
  demo: false,
  staging: false,
  apiUrlPrefix: '',
  gpiClientId: '',
  auth0: {
    domain: '',
    client_id: '',
    redirect_uri: '',
    audience: '',
  },
  adminApiUrlPrefix: '',
  adminAuth0: {
    domain: '',
    client_id: '',
    redirect_uri: '',
    audience: '',
    organization: '',
  },
  datadog: {
    site: undefined,
    logs: {
      clientToken: undefined,
    },
    rum: {
      applicationId: undefined,
      clientToken: undefined,
    },
  },
  googleTagManager: {
    gtm_id: '',
    gtm_auth: undefined,
    gtm_preview: undefined,
  },
  zendesk: {
    widgetKey: undefined,
  },
};

export const env = (): Environment | undefined => {
  if (!isClientSide()) return;
  return environment[window.location.origin] ?? defaultEnvironment;
};

export const getEnvironmentName = (env?: Environment): EnvironmentName | undefined => {
  if (!env || !isClientSide()) return;
  if (env.staging) return 'stg';
  if (env.demo) return 'dem';
  if (env.production) return 'prd';
  return;
};
