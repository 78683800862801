import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { ReqOptions, useAuthReqOptions } from './use-auth-req-options';
import { useHandleHttpError } from '~/common/hooks/http-error/use-handle-http-error';

type UseFetchOption<T> = Partial<{
  revalidateOnMount: boolean;
  dedupingInterval: number;
  refreshInterval: number;
  onSuccess?: (data: T, key: string, config: SWRConfiguration) => void;
}>;

/**
 * 非同期によるデータの取得を行うカスタムフック
 * 対応する更新は useMutate を使う
 * @see useMutate
 */
const useFetch = <T>(
  key: string | string[] | null,
  callApiF: (reqOps: ReqOptions) => Promise<T>,
  option?: UseFetchOption<T>,
) => {
  const { getAuthReqOptions } = useAuthReqOptions();
  const { handleHttpError } = useHandleHttpError();

  const result = useSWR(
    key,
    async () => {
      const reqOptions = await getAuthReqOptions();
      return await callApiF(reqOptions);
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      refreshInterval: 0,
      ...option,
    },
  );

  if (result.error) {
    handleHttpError(result.error);
  }

  return result;
};

/** Suspense 対応 Fetch */
const useSuspenseFetch = <T>(
  key: string | string[] | null,
  callApiF: (reqOps: ReqOptions) => Promise<T>,
  option?: UseFetchOption<T>,
): SWRResponse<
  T,
  Error,
  {
    suspense: true; // 呼び出し側で SWRResponse を再指定する場合は suspense: true を指定するのを忘れないこと
    shouldRetryOnError: false;
    revalidateOnFocus: false;
  }
> => {
  const { getAuthReqOptions } = useAuthReqOptions();
  const { handleHttpError } = useHandleHttpError();

  const result = useSWR(
    key,
    async () => {
      const reqOptions = await getAuthReqOptions();
      return await callApiF(reqOptions);
    },
    {
      suspense: true,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      refreshInterval: 0,
      ...option,
    },
  );

  if (result.error) {
    handleHttpError(result.error);
  }

  return result;
};

export { useFetch, useSuspenseFetch };
export type { UseFetchOption };
