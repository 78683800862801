import React, { ReactNode, useCallback } from 'react';
import { useMap, useSet, useUnmount } from 'react-use';
import { css } from '@emotion/react';
import { ToastContext, ToastContextProps } from './ToastContext';
import { Toast, ToastProps } from '~/common/components/toast/Toast';

export type ToastProviderProps = {
  children: ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toastMap, { set, remove }] = useMap<Record<string, ToastProps>>();
  const [removeTimerSet, { add, remove: removeTimer }] = useSet<NodeJS.Timeout>();

  // コンポーネントがアンマウントされるときに全てのタイマーをクリア
  useUnmount(() => removeTimerSet.forEach((timeout) => clearTimeout(timeout)));

  const addToast = useCallback(
    (toastProps: ToastProps) => {
      set(toastProps.text, toastProps);
      if (toastProps.type !== 'success') return;
      // 成功トーストの場合は、4秒後に非表示にする
      const timeoutId = setTimeout(() => {
        remove(toastProps.text);
        removeTimer(timeoutId);
      }, 4000);
      add(timeoutId);
    },
    [add, remove, removeTimer, set],
  );

  const closeToast = useCallback(({ text }: ToastProps) => remove(text), [remove]);

  return (
    <div>
      <MemorizedToastProvider addToast={addToast}>{children}</MemorizedToastProvider>
      <div
        css={[
          styles.toastProvider,
          Object.keys(toastMap).length > 0 ? styles.toastProviderHasToast : null,
        ]}
      >
        {Object.values(toastMap).map((toastProps: ToastProps) => (
          <Toast
            css={styles.toastProviderToast}
            {...toastProps}
            onClickButton={() => {
              if (toastProps.onClickButton) toastProps.onClickButton();
              closeToast(toastProps);
            }}
            key={toastProps.text}
          />
        ))}
      </div>
    </div>
  );
};

const MemorizedToastProvider: React.FC<ToastContextProps> = React.memo(
  function MemorizedToastProvider({ addToast, children }) {
    return <ToastContext.Provider value={{ addToast }}>{children}</ToastContext.Provider>;
  },
);

const styles = {
  toastProvider: css`
    position: fixed;
    z-index: 1300;
    top: 0;
    left: 0;
    width: 100vw;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  `,

  toastProviderHasToast: css`
    padding-top: var(--spacing-8);
    height: 100vh;
  `,

  toastProviderToast: css`
    margin-top: var(--spacing-3);
  `,
};
