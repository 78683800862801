import Head from 'next/head';
import React, { memo, ReactNode } from 'react';
import { css } from '@emotion/react';
import { HeaderInfoProvider } from '~/common/components/layout/HeaderInfoProvider';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import { LoginUserInfo } from '~/common/providers/auth/login-user';
import { DrawerManager } from '~/common/components/drawer';
import { LabsAppHeader } from '~/common/components/layout/app-header/LabsAppHeader';

type LoginUserInfoForAppLayout = Pick<LoginUserInfo, 'firstName' | 'lastName' | 'role'>;

type Tenant = {
  id: string;
  name: string;
};

type AppLayoutProps = {
  loginUser: LoginUserInfoForAppLayout;
  logout: () => void;
  enabledFeatureMap: EnabledFeatureMap;
  tenant: Tenant;
  children: ReactNode;
};

const LabsAppLayout: React.FC<AppLayoutProps> = memo(function LabsAppLayout({ children }) {
  return (
    <div css={styles.container}>
      <Head>
        <title />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <HeaderInfoProvider>
        <DrawerManager css={styles.body}>{children}</DrawerManager>
        <div css={styles.appHeaderContainer}>
          <LabsAppHeader />
        </div>
      </HeaderInfoProvider>
    </div>
  );
});

const HEADER_HEIGHT = '56px';

const styles = {
  container: css`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: ${HEADER_HEIGHT} calc(100vh - ${HEADER_HEIGHT});
    grid-template-columns: max-content 1fr;
    justify-content: start;
    overflow: hidden;
  `,
  appHeaderContainer: css`
    grid-row: 1;
    grid-column: 2 / 3;
  `,
  body: css`
    grid-row: 2;
    grid-column: 2 / 3;
    overflow: auto;
    position: relative;
    z-index: 0;
  `,
};

export { LabsAppLayout };
export type { AppLayoutProps, LoginUserInfoForAppLayout, Tenant };
