import { Role } from '~/common/providers/auth/role';

const financialSheetsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
  'MANAGER',
  'GENERAL',
];

export { financialSheetsAllowedRoles };
