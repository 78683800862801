import { createContext, useContext, ElementRef } from 'react';

type DrawerManagerContextValue = {
  container: ElementRef<'div'> | null;
};

const DrawerManagerContext = createContext<DrawerManagerContextValue | null>(null);

const useDrawerManager = () => {
  const value = useContext(DrawerManagerContext);
  if (value == null) {
    throw new Error('DrawerManagerContext でラップするのを忘れています。');
  }
  return value;
};

const DrawerManagerProvider = DrawerManagerContext.Provider;

export { useDrawerManager, DrawerManagerProvider };
