import Head from 'next/head';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { HeaderInfoProvider } from '~/common/components/layout/HeaderInfoProvider';
import { AdminLayoutBody } from '~/common/components/admin-layout/AdminLayoutBody';
import { AdminAppBar } from '~/common/components/admin-layout/AdminAppBar';

type Props = {
  currentUrl: string;
  children: ReactNode;
};

const AdminLayout: React.FC<Props> = React.memo(function AdminLayout({ children, currentUrl }) {
  return (
    <div css={styles.container}>
      <Head>
        <title />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <HeaderInfoProvider>
        <div css={styles.appBarContainer}>
          <AdminAppBar />
        </div>
        <div css={styles.body}>
          <AdminLayoutBody currentUrl={currentUrl}>{children}</AdminLayoutBody>
        </div>
      </HeaderInfoProvider>
    </div>
  );
});

const styles = {
  container: css`
    background-color: var(--color-page-background);
  `,

  appBarContainer: css`
    position: sticky;
    top: 0;
    height: 64px;
    box-sizing: border-box;
    z-index: 300;
  `,

  body: css`
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
  `,
};

export { AdminLayout };
