import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Icon } from '~/common/components/icon';
import { Span } from '~/common/components/span';

type Props = {
  icon: ReactElement;
  text: string;
  isActive?: boolean;
};

const AdminSideBarItem: React.FC<Props> = React.memo(function AdminSideBarItem({
  text,
  icon,
  isActive,
}) {
  const fontColor = isActive ? 'primary' : 'dark';
  return (
    <div css={[styles.item, isActive ? styles.itemActive : undefined]}>
      <Icon size={'l'} color={fontColor}>
        {icon}
      </Icon>
      <div css={styles.text}>
        <Span level={2} color={fontColor}>
          {text}
        </Span>
      </div>
    </div>
  );
});

const styles = {
  item: css`
    height: 52px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-4) var(--spacing-3) var(--spacing-4) var(--spacing-5);

    &:hover {
      background-color: var(--color-primary-2);
    }
  `,

  itemActive: css`
    background-color: var(--color-primary-2);
  `,

  text: css`
    margin-left: var(--spacing-3);
    line-height: 24px;
  `,
};

export { AdminSideBarItem };
