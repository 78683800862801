import React, { ReactNode, useCallback, useState } from 'react';
import { HeaderInfo, HeaderInfoContext } from '~/common/components/layout/HeaderInfoContext';

type HeaderInfoProviderProps = {
  children?: ReactNode;
};

const HeaderInfoProvider = React.memo(function HeaderInfoProvider({
  children,
}: HeaderInfoProviderProps) {
  const [headerInfo, setHeaderInfoBase] = useState<HeaderInfo>({
    title: '',
    backPageUrl: undefined,
  });

  const setHeaderInfo = useCallback((headerInfo: HeaderInfo) => {
    const portalRoot = document.getElementById('headerTitle');
    if (!portalRoot) return null;
    const firstChild = portalRoot.firstElementChild;
    if (
      firstChild &&
      firstChild instanceof HTMLElement &&
      firstChild.tagName === 'H1' &&
      firstChild.style.display === 'none'
    ) {
      firstChild.style.display = ''; // EditableHeaderTitlePortal で none にしているので、戻す
    }
    setHeaderInfoBase(headerInfo);
  }, []);

  return (
    <HeaderInfoContext.Provider value={{ headerInfo, setHeaderInfo }}>
      {children}
    </HeaderInfoContext.Provider>
  );
});

export { HeaderInfoProvider };
