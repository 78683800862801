import React, { ReactNode, useEffect } from 'react';
import { Role } from '~/common/providers/auth/role';
import { useToast } from '~/common/components/toast/use-toast';

type Props = {
  myRole: Role | undefined;
  allowedRoles: Role[];
  withErrorToast?: boolean;
  children: ReactNode;
};

/** @deprecated NewRoleGuard を利用すること */
const RoleGuard: React.FC<Props> = React.memo(function RoleGuard({
  myRole,
  allowedRoles,
  children,
  withErrorToast,
}) {
  const { addToast } = useToast();

  const permitted = myRole && allowedRoles.includes(myRole);

  useEffect(() => {
    if (withErrorToast != null && !permitted) {
      addToast({
        text: 'この機能にアクセスする権限がありません。管理者にお問い合わせください',
        type: 'error',
      });
    }
  }, [withErrorToast, addToast, permitted]);

  return <>{permitted && children}</>;
});

export { RoleGuard };
