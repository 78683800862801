import React, { PropsWithChildren } from 'react';
import { Circle } from 'react-feather';
import { css, keyframes } from '@emotion/react';
import { Icon } from '~/common/components/icon';

type SpinnerProps = PropsWithChildren<{
  className?: string; // TODO: Visual Regression Test 実装後、削除できるか試す
  show: boolean;
}>;

const Spinner: React.FC<SpinnerProps> = React.memo(function Spinner({ children, show, className }) {
  return (
    <div
      className={className}
      // TODO: spinnerContainer が持つflexスタイルを撤去するリファクタをする
      css={[styles.spinnerContainer, show && styles.spinnerContainerBlockEvent]}
    >
      {show && (
        <div
          css={styles.spinnerBackground}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div css={[styles.spinnerBackgroundSpinner, styles.spinner]}>
            <Icon css={styles.icon} size={'xl'} color={'dark'}>
              <Circle />
            </Icon>
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

const muiCircularProgressKeyframesCircularRotate = keyframes`
  0% {
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
`;
const circleProgressKeyframe = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`;

const styles = {
  icon: css`
    animation: ${circleProgressKeyframe} 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    stroke: currentColor;
  `,
  spinnerContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 30px;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  spinnerContainerBlockEvent: css`
    pointer-events: none;
  `,
  spinnerBackground: css`
    background-color: rgba(255, 255, 255, 0.67);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1200;
  `,
  spinnerBackgroundSpinner: css`
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  `,
  spinner: css`
    display: flex;
    animation: ${muiCircularProgressKeyframesCircularRotate} 1.4s linear infinite;
  `,
};

export { Spinner };
export type { SpinnerProps };
