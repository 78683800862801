import { useCallback, useState } from 'react';
import { useMountedRef } from '~/common/hooks/use-mounted-ref';

/**
 * 非同期でsetState系の処理をするとき元のコンポーネントが既に破棄されている時にメモリリークを起こさないために使用する
 * このフックが返すsetStateは元のコンポーネントが破棄されているかをuseRefを使って判定して破棄されている場合はsetStateしない
 *
 * 例)
 * ```
 * const [count, setCount] = useSafeState(0);
 *
 * const asyncFunction = async () => {
 *   const count = await new Api().fetchCount();
 *   setCount(count);
 * }
 *
 * useEffect(() => {
 *  asyncFunction();
 * });
 * ```
 *
 * 下記のような警告がコンソールに出たらこのフックを使うことを考えたほうがいい
 * Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions
 *
 * 参考にした実装: https://qiita.com/macotok/items/87a260988376bcfa2ffd
 */
type NewValue<T> = T | ((currentValue: T) => T);

const useSafeState = <T>(defaultValue: T): [T, (newValue: NewValue<T>) => void] => {
  const mountedRef = useMountedRef();
  const [state, setState] = useState(defaultValue);

  const safeSetState = useCallback(
    (newValue: NewValue<T>) => {
      if (mountedRef.current) {
        setState(newValue);
      }
    },
    [mountedRef],
  );

  return [state, safeSetState];
};

export { useSafeState };
