import { createContext } from 'react';

type HeaderInfoProps = {
  headerInfo: HeaderInfo;
  setHeaderInfo: (headerInfo: HeaderInfo) => void;
};

type HeaderInfo = {
  title: string;
  backPageUrl?: string;
  editable?: boolean;
};

const HeaderInfoContext = createContext<HeaderInfoProps>({
  headerInfo: {
    title: '',
    backPageUrl: undefined,
    editable: false,
  },
  setHeaderInfo: () => undefined,
});

export { HeaderInfoContext };
export type { HeaderInfoProps, HeaderInfo };
