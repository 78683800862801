import React, { ReactNode } from 'react';
import { EnabledFeatureMap, useEnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';

type Props = {
  requiredFeatures: Array<keyof EnabledFeatureMap>;
  children: ReactNode;
};

/**
 * FeatureToggle を参照し、requiredFeatures 似指定したフラグがすべて有効の場合は children を表示する
 * 非同期通信中は null を返す
 **/
const FeatureGuard: React.FC<Props> = ({ requiredFeatures, children }) => {
  const { data: enabledFeatureMap, isValidating } = useEnabledFeatureMap();
  if (requiredFeatures.length === 0 || isValidating) return null;
  const permitted = requiredFeatures.every(
    (featureName) => enabledFeatureMap && enabledFeatureMap[featureName],
  );
  return <>{permitted && children}</>;
};

export { FeatureGuard };
