import React, { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useDrawerManager } from './DrawerManagerContext';

type DrawerPortalProps = PropsWithChildren;

const DrawerPortal: FC<DrawerPortalProps> = React.memo(function DrawerPortal({ children }) {
  const { container } = useDrawerManager();
  return container ? createPortal(children, container) : null;
});

export { DrawerPortal };
