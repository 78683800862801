import { parseISO } from 'date-fns';

function reviver(_: string, value: string) {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value) ? parseISO(value) : value;
}

const getItemFromLocalStorage = <T>(key: string): T | undefined => {
  const rawString = localStorage.getItem(key);
  return rawString ? JSON.parse(rawString, reviver) : undefined;
};

const setItemToLocalStorage = <T>(key: string, value: T): void => {
  const rawString = JSON.stringify(value);
  localStorage.setItem(key, rawString);
};

const removeItemInLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

const clearAllItemsInLocalStorage = (): void => {
  localStorage.clear();
};

export {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  removeItemInLocalStorage,
  clearAllItemsInLocalStorage,
};
