import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { env } from '~/app/environment';

type ReqOptions = {
  headers: {
    authorization: string;
  };
};

type UseAuthReqOptions = {
  getAuthReqOptions: () => Promise<ReqOptions>;
};

const useAuthReqOptions = (): UseAuthReqOptions => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuthReqOptions = useCallback(async () => {
    const environment = env();
    if (!environment) {
      throw Error('環境変数が設定されていません');
    }
    const token = await getAccessTokenSilently();

    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }, [getAccessTokenSilently]);

  return { getAuthReqOptions };
};

export { useAuthReqOptions };
export type { ReqOptions, UseAuthReqOptions };
