import { useEffect, useRef } from 'react';

const useMountedRef = (): { current: boolean } => {
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
};

export { useMountedRef };
