import React, { ReactNode } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { env, getEnvironmentName } from '~/app/environment';
import { GlobalBlockingErrorHandler } from '~/app/GlobalBlockingErrorHandler';

type ErrorBoundaryState = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

type ErrorBoundaryProps = {
  children?: ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });
  }

  componentDidMount(): void {
    const environment = env();
    if (environment && environment.datadog.logs.clientToken) {
      datadogLogs.init({
        clientToken: environment.datadog.logs.clientToken ?? '',
        site: environment.datadog.site,
        service: 'loglass-web',
        env: getEnvironmentName(environment),
        sessionSampleRate: 100,
        forwardErrorsToLogs: true,
        version: process.env.NEXT_PUBLIC_COMMIT_HASH,
        beforeSend: (log) => {
          // バックエンドとの疎通エラーはバックエンド側で取れるため、エラーログに流さない
          if (log.http != null && log.http.status_code != null) {
            return false;
          }
          return true;
        },
      });
    }
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    return (
      <>
        {!error && children}
        <GlobalBlockingErrorHandler error={error} errorInfo={errorInfo} />
      </>
    );
  }
}

export { ErrorBoundary };
