import React, { memo } from 'react';
import { css } from '@emotion/react';
import Head from 'next/head';
import { Mail as MailIcon, LogIn as LogInIcon, Home as HomeIcon } from 'react-feather';
import { useHeaderInfo } from '~/common/components/layout/use-header-info';
import { Span } from '~/common/components/span';
import { LabsLink } from '~/features/labs/layout/LabsLink';

const LabsAppHeader = memo(function LabsAppHeader() {
  const { headerInfo } = useHeaderInfo();

  return (
    <div css={styles.appHeader}>
      <Head>
        <title>{headerInfo.title}</title>
      </Head>
      <div css={styles.logo}>
        <img
          src="/assets/labs-logo.svg"
          alt="Loglass Labs"
          className="logo"
          width="auto"
          height="24"
        />
      </div>

      <div css={styles.appHeaderRight}>
        <div css={styles.headerSection}>
          <LabsLink
            href="https://forms.gle/gsztqJwA35MezWzQ6"
            target={'_blank'}
            showIcon={true}
            icon={<MailIcon />}
          >
            <Span level={2} css={styles.headerSectionLabel}>
              機能に関するお問い合わせ
            </Span>
          </LabsLink>

          <LabsLink href="/labs" showIcon={true} icon={<HomeIcon />} iconSize={'m'}>
            <Span level={2} css={styles.headerSectionLabel}>
              Labs
            </Span>
          </LabsLink>

          <LabsLink href="/" target={'_blank'} showIcon={true} icon={<LogInIcon />}>
            <Span level={2} css={styles.headerSectionLabel}>
              Loglassに戻る
            </Span>
          </LabsLink>
        </div>
      </div>
    </div>
  );
});

const styles = {
  logo: css`
    margin-top: var(--spacing-2);
    flex-shrink: 2;
    width: auto;
    height: 34px;
  `,
  appHeader: css`
    display: flex;
    align-items: center;
    padding: var(--spacing-3) var(--spacing-7);
    border-bottom: var(--border-width-s) solid var(--color-neutral-3);
    background-color: var(--color-base-background);
    height: 56px;
  `,
  appHeaderRight: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  `,
  headerSection: css`
    display: flex;
    align-items: center;
    gap: var(--spacing-5);
    padding-right: var(--spacing-6);
    border-right: var(--border-width-s) solid var(--color-neutral-5);
  `,
  headerSectionLabel: css`
    word-break: keep-all;
    font-weight: var(--font-weight-semi-bold);
  `,
};

export { LabsAppHeader };
