import { createContext, ReactNode } from 'react';
import { ToastProps } from '~/common/components/toast/Toast';

export type ToastContextProps = {
  addToast: (toastProps: ToastProps) => void;
  children?: ReactNode;
};

export const ToastContext = createContext<ToastContextProps>({
  addToast: () => undefined,
});
