import { ElementRef, PropsWithChildren, useState } from 'react';
import { css } from '@emotion/react';
import { DrawerManagerProvider } from '~/common/components/drawer/DrawerManagerContext';

type DrawerManagerProps = PropsWithChildren<{
  className?: string;
}>;

/**
 * ドロワー表示枠のロジックを責務として持つ
 */
const DrawerManager = ({ className, children }: DrawerManagerProps) => {
  const [container, setContainer] = useState<ElementRef<'div'> | null>(null);
  return (
    <DrawerManagerProvider value={{ container }}>
      <div className={className} css={styles.container}>
        <div css={styles.childrenContainer}>{children}</div>
        <div ref={setContainer} id="drawer" css={styles.drawerContainer}>
          {/* Drawerコンテンツの描画領域 */}
        </div>
      </div>
    </DrawerManagerProvider>
  );
};

const styles = {
  container: css`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 1fr auto;
  `,
  childrenContainer: css`
    grid-row: 1;
    grid-column: 1 / 2;
    overflow: auto;
  `,
  drawerContainer: css`
    grid-row: 1;
    grid-column: 2;
    display: grid;
    justify-items: end;
    grid-template-rows: 100%;
  `,
};

export { DrawerManager };
