type HttpErrorMessageMap = {
  [key: number]: {
    [key: string]: string;
    default: string;
  };
  default: string;
};

const VERSION_CONFLICT_MSG =
  '対象データが新しいデータで更新されています。ページを更新して再度お試しください。';
const COMMON_ERROR_MSG = 'エラーが発生しました。ページを更新して再度お試しください。';

const httpErrorMessageMap: HttpErrorMessageMap = {
  0: {
    default: '通信エラーが発生しました。しばらく待ってから再度お試しください。',
  },
  400: {
    default: COMMON_ERROR_MSG,
  },
  401: {
    default: '一定時間が経過したためログアウトしました。再度ログインし直してください。',
  },
  403: {
    default: 'ページを表示する権限がありません。',
  },
  404: {
    default: COMMON_ERROR_MSG,
  },
  405: {
    default: COMMON_ERROR_MSG,
  },
  409: {
    default: VERSION_CONFLICT_MSG,
  },
  413: {
    default:
      'ファイルのサイズが大きすぎるため、アップロードできませんでした。ファイルサイズを確認のうえ、再度お試しください。',
  },
  500: {
    default: COMMON_ERROR_MSG,
  },
  503: {
    default: COMMON_ERROR_MSG,
  },
  504: {
    default:
      'タイムアウトエラーが発生しました。ページを更新して再度お試しください。再度実行してもエラーとなる場合はカスタマーサポートへお問い合わせください。',
  }, // TODO: ALB経由の場合、レスポンスにボディがあるためデフォルトメッセージが使用されない。現状では利用者にタイムアウトであることを伝えられないので必要に応じて改善する。参照: LOG-7203
  default: COMMON_ERROR_MSG,
};

export { httpErrorMessageMap, COMMON_ERROR_MSG };
