import { useContext } from 'react';
import { useMount } from 'react-use';
import {
  HeaderInfo,
  HeaderInfoContext,
  HeaderInfoProps,
} from '~/common/components/layout/HeaderInfoContext';

const useHeaderInfo: (props?: HeaderInfo) => HeaderInfoProps = (props) => {
  const { setHeaderInfo, ...rest } = useContext(HeaderInfoContext);

  useMount(() => {
    if (props != null && !compareHeaderInfo(props, rest.headerInfo)) setHeaderInfo(props);
  });

  return {
    setHeaderInfo,
    ...rest,
  };
};

const compareHeaderInfo = (a: HeaderInfo, b: HeaderInfo): boolean =>
  a.title === b.title && a.backPageUrl === b.backPageUrl && a.editable === b.editable;

export { useHeaderInfo };
